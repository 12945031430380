import React from 'react'

import './styles.css'

class CategoryDataList extends React.Component {

    render() {
        return(
            <div className="category_data_list">
                
            </div>
        )
    }
}

export default CategoryDataList