import React from 'react'
import Select from 'react-select'

import './styles.css'

const SearchFilter = (props) => {
    const close = () => {
        props.closeSearchFilter()
    }
    
    return(
        <div className="search_filter" >
            <div className="close">
                <span className="filt">Advanced Filter</span>
                <span onClick={close} className="close_icon">x</span>
            </div>
            <div className="select_box">
                <Select 
                    styles={{ zIndex: "99999"}}
                    value={props.selectedBizType}
                    onChange={props.handleBizTypeChange}
                    options={props.bizTypeData} 
                    placeholder="Select Business Type"
                />
            </div>

            <div className="select_box" style={{paddingBottom: "20px"}}>
                <Select 
                    value={props.selectedCategory}
                    onChange={props.handleCategoryChange}
                    options={props.categoryData} 
                    placeholder="Select Category"
                />
            </div>
        </div>
    )
}

export default SearchFilter