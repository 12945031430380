import React from 'react'
const Loader = (props) => {
    return (
        <div className="d-flex justify-content-center backdrop" style={{top: props.imgUpload ? "35%" : "50%", left: props.imgUpload ? "33%" : "45%"}}>
            <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem", color: props.color ? props.color : "#28a745" }}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loader