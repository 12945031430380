/**
 *  WishList Page Set
 */
import React, { Component } from 'react';
import { Row, Col, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import 'react-toastify/dist/ReactToastify.min.css';
import './styles.css'
import * as actions from '../../actions'
import MetaForShare from '../../widgets/MetaForShare'
import noImage from '../../assets/img/no-image.jpg'

class WishList extends Component {


    constructor(props) {
        super(props);
        this.ReadWishListItems = this.ReadWishListItems.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    ReadWishListItems() {
        return JSON.parse(localStorage.getItem("wishlist_item"));
    }

    removeFromWishList = (id) => {
        let wishList = []
        let localData = localStorage.getItem("wishlist_item")
        if(localData) {
            wishList = JSON.parse(localData)
            wishList = wishList.filter(cart => cart.item_id !== id)
        }
        this.setState({
            wishList,
        })
        localStorage.setItem("wishlist_item", JSON.stringify(wishList))
        this.props.getWishListCount()
    }

    render() {
        return (

            <div className="site-content">
                <MetaForShare 
                    title="MMDP - Wish List" 
                    url={`http://localhost:3000/${this.props.match.url}`}
                    img="https://www.cindysrecipe.com/wp-content/uploads/2018/03/favourites-267x200.png"
                    desc="Wish List of the Glories"
                    siteName="Glories"
                />
                <div className="content-wrapper section-ptb">
                    <Container>
                        <Row className="w_100" style={{marginBottom: "80px"}}>
                                {(this.ReadWishListItems() != null && this.ReadWishListItems().length > 0) ?
                            <Col sm={12} className="pd_none">
                                    <div className="table-responsive-md">
                                        <Table className="table wishlist-table cart-table" style={{width: "100%"}}>
                                            <thead>
                                                <tr>
                                                    <th clas="product-remove"></th>
                                                    <th className="product-thumbnail" />
                                                    <th className="product-name">
                                                        <span className="nobr">Product Name</span>
                                                    </th>
                                                    <th className="product-price">
                                                        <span className="nobr">
                                                            Unit Price </span>
                                                    </th>
                                                    <th className="product-add-to-cart" />
                                                </tr>


                                                {this.ReadWishListItems().map((CartItem, index) => (
                                                    <tr>
                                                        <td className="product-remove">
                                                            <Link onClick={() => this.removeFromWishList(CartItem.item_id)} className="remove"></Link>
                                                        </td>
                                                        <td className="res product-thumbnail">
                                                            <Link to="/productDetail">
                                                                {CartItem.photo && JSON.parse(CartItem.photo).length > 0 ?
                                                                    <img src={JSON.parse(CartItem.photo)[0]} alt="product" />
                                                                : 
                                                                    <img src={noImage} alt="prodct" />
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td className="res product-name">
                                                            {CartItem.item_name}
                                                        </td>
                                                        <td className="res product-price" data-title="Price">
                                                            ${CartItem.item_price}
                                                        </td>
                                                        {/* <td className="product-stock-status">
                                                            <span className="wishlist-in-stock">{CartItem.StockStatus}</span>
                                                        </td> */}
                                                        <td className="res product-add-to-cart">
                                                            <a href={CartItem.url} className="add_to_cart_button">View Item</a>
                                                        </td>
                                                    </tr>

                                                ))}

                                            </thead>
                                        </Table></div>
                            </Col>
                                    :
                                    <div className="not-found">
                                        <h4 className="d-block">Favourite List empty</h4>
                                        <Link to="/mmdp" className="btn btn-solid">Back to Home</Link>
                                    </div>
                                }
                        </Row>
                    </Container>
            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        wishlistCount: state.wishlistCount,
        shoppingCartCount: state.shoppingCartCount
    }
}

export default connect(
    mapStateToProps, actions
)(WishList)
