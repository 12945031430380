import React from 'react'

import './styles.css'
import { checkLogin, getOrderDetail } from '../../api'
import LoginFacebook from '../../widgets/Facebook'
import Loader from '../../widgets/Loader'

class OrderDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            login: true,
            orderDetail: []
        }
    }

    async componentDidMount() {
        let login = await checkLogin()
        let shopId = this.props.match.params.shopId
        let businessId = this.props.match.params.businessId
        let saleNo = this.props.match.params.saleNo
        if(login) {
            let orderDetail = await getOrderDetail(saleNo, shopId, businessId)
            if(orderDetail.code === '200') {
                this.setState({
                    orderDetail: orderDetail.data
                })
            } else {
                this.setState({
                    login: false
                })
            }
        }
        this.setState({
            login
        })
    }

    editCustomerDetail = async () => {
        this.setState({
            login: true,
        })
    }

    render() {
        return(
            this.state.login ?
                this.state.orderDetail ?
                    <div className="bottom-space">
                        <div className="app_bar ms_row border_bottom">
                            <div className="col_2 t_left">
                                <button><i onClick={e => this.props.history.goBack()} className="fa fa-chevron-left" aria-hidden="true"></i></button>
                            </div>
                            <div className="search_section col_8">
                                <h2 style={{
                                    fontSize: "1.2em"
                                }}>Order Detail</h2>
                            </div>
                        </div>
                        
                        <div style={{height: "45px"}}></div>
                        
                        <div className="order_container">
                            <div className="head">
                                <span className="name"> {this.state.orderDetail.shop_name} </span>
                                <span className="sale_no"><span className="title">Sale No: </span>{this.state.orderDetail.sale_no}-{this.state.orderDetail.shop_id}-{this.state.orderDetail.business_id} </span>
                                <span className="date">{this.state.orderDetail.sale_date}</span>
                                <span>{this.state.orderDetail.transaction_status}</span>
                            </div>
                            <div className="item_container">
                                {this.state.orderDetail.items && this.state.orderDetail.items.length > 0
                                    && this.state.orderDetail.items.map(item => (
                                        <div className="items">
                                            <span className="left name">{item.quantity}</span>
                                            <span className="left item">
                                                <span className="inner">{item.item_name}</span>
                                                {item.variants && item.variants.length > 0 ?
                                                    <span className="inner">
                                                        {item.variants.map(variant => (
                                                            <span className="inner">
                                                                {variant.choice}({variant.price})
                                                            </span>
                                                        ))}
                                                    </span>
                                                : ""}


                                            </span>
                                            <span className="price">{item.item_price} Ks</span>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="foot">
                                <div className="total">
                                    <span className="title">Sub Total</span>
                                    <span className="right">{this.state.orderDetail.sub_total_amount} Ks</span>
                                </div>
                                <div className="total">
                                    <span className="title">Discount</span>
                                    <span className="right">{this.state.orderDetail.discount_amount} Ks</span>
                                </div>
                                <div className="total">
                                    <span className="title">Tax</span>
                                    <span className="right">{this.state.orderDetail.tax_amount} Ks</span>
                                </div>
                                <div className="total">
                                    <span className="title">Total</span>
                                    <span className="right">{parseFloat(this.state.orderDetail.net_amount) + parseFloat(this.state.orderDetail.tax_amount)} Ks</span>
                                </div>
                            </div>
                        </div>
                    </div>
                : <Loader />
            : 
            <LoginFacebook editCustomerDetail={this.editCustomerDetail} />
        )
    }
}

export default OrderDetail