import React from 'react'
import Loader from '../../widgets/Loader'
import { getBusinessTypes } from './../../api/index'
import { Link } from 'react-router-dom'
import './styles.css'
import MetaForShare from '../../widgets/MetaForShare'

class AllBizTypes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            businessTypeList: []
        }
    }

    async componentDidMount() {
        let businessTypeList = await getBusinessTypes()
        this.setState({
            businessTypeList: businessTypeList.data
        })
    }

    render() {
        return (
            <div className="business_types bottom-space">
                <MetaForShare 
                    title="MMDP - All Business Type" 
                    url={`http://localhost:3000/${this.props.match.url}`}
                    img=""
                    desc="Shop List of the Glories"
                    siteName="Glories"
                />
                {this.state.businessTypeList && this.state.businessTypeList.length > 0 ?
                    <ul>
                        {this.state.businessTypeList.map(bizType => (
                            <li key={bizType.biz_type_id}>
                                <Link to={`/mmdp/shopList/${bizType.biz_type_id}`}>
                                    <i><img src={bizType.image_url} alt="menu" /></i>
                                    <span>{bizType.biz_type_name}</span>
                                </Link>
                            </li>
                            ))
                        }
                    </ul>
                : <Loader />}
            </div>
        )
    }
}

export default AllBizTypes

