import React from 'react'

import './styles.css'

const ComingSoon = () => {

    return (
        <div className="comingsoon">
            <img src={"https://www.kindpng.com/picc/m/44-443767_coming-soon-poster-png-transparent-png.png"} alt="coming soon" />
        </div>
    )
}

export default ComingSoon