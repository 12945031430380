import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';

import './styles.css'
import Product from '../../widgets/ProductMiniDetail'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import Loader from '../../widgets/Loader';
import SearchFilter from '../../widgets/SearchFilter';

class ItemSearch extends React.Component {

    constructor() {
        super()
        this.state = {
            limit:8,
            hasMoreProduct: true,

            filterActive: '',
            businessCategoryList: [],
            businessId: 48,
            itemName: "",
            businessName: "",

            businessItemList: [],
            layoutStyle: "",
            shopId: 1,
            index: 0,
            length: 10,
            totalRows: 0,

            
            items: Array.from({ length: 20 }),
            hasMore: true,
            searchName: "",
            notFound: false,
            stopScroll: false,
            bizTypeId: "",
            categoryId: "",

            
            showFilter: false,
            categoryData: [],
            bizTypeData: [],
            selectedBizType: {
                value: "",
                label: "Choose Business Type"
            },
            selectedCategory: {
                value: "",
                label: "Choose Categroy"
            },
        }
        this.searchInput = React.createRef();
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search)
        let itemName = query.itemName
        let bizTypeId = query.bizTypeId
        let siteCategoryId = query.siteCategoryId
        let layoutStyle = localStorage.getItem('layoutStyle')
        this.setState({
            layoutStyle: layoutStyle,
            itemName: itemName,
            searchName: itemName,
            bizTypeId: bizTypeId,
            categoryId: siteCategoryId
        })
        this.props.searchItemsAction(itemName, bizTypeId, siteCategoryId, "item", this.state.index, this.state.length)
        if(!this.props.categorys.code) {
            this.props.getPreCategories()
        }
        if(!this.props.businessTypes.code) {
            this.props.getBusinessTypesForApp()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.searchItems !== nextProps.searchItems) {
            if(nextProps.searchItems.code === '200') {
                if(nextProps.searchItems.data)
                    if(nextProps.searchItems.data.items.length > 0) {
                        this.setState({
                            businessItemList: this.state.businessItemList.concat(Array.from(nextProps.searchItems.data.items)),
                            notFound: false,
                            stopScroll: false
                        })
                    } else {
                        if(this.state.businessItemList.length > 0) {
                            this.setState({
                                stopScroll: true
                            })
                        } else {
                            this.setState({
                                businessItemList: [],
                                notFound: true,
                                stopScroll: false
                            })
                        }
                    }
                else {
                    this.setState({
                        businessItemList: [],
                        notFound: true,
                        stopScroll: false
                    })
                }
            } else {
                this.setState({
                    stopScroll: true
                })
            }
        }
    }
    
    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    fetchMoreData = async () => {
        if(this.state.stopScroll) {
            this.setState({
                hasMore: false
            })
            return
        }
        let ind = this.state.index + this.state.length 
        this.props.searchItemsAction(this.state.searchName, this.state.bizTypeId, this.state.categoryId, "item", ind, this.state.length)
        this.setState({
            // businessItemList: this.state.businessItemList.concat(Array.from(items.data)),
            index: ind
        });
    };

    changeLayout = (layoutStyle) => {
        localStorage.setItem('layoutStyle', layoutStyle)
        this.setState({
            layoutStyle
        })
    }

    showSearchFilter = () => {
        this.searchInput.current.blur()

        this.searchInput.current.focus()
        let categoryTmp = [{
            value: "",
            label: "Choose Categroy"
        }]
        let bizTypeTmp = [{
            value: "",
            label: "Choose Business Type"
        }]
        for(let category of this.props.categorys.data) {
            let data = {
                value: category.site_category_id,
                label: category.site_category_name
            }
            if(parseInt(category.site_category_id) === parseInt(this.state.categoryId)) {
                this.setState({
                    selectedCategory: data
                })
            }
            categoryTmp.push(data)
        }

        for(let bizType of this.props.businessTypes.data) {
            let data = {
                value: bizType.biz_type_id,
                label: bizType.biz_type_name
            }
            if(parseInt(bizType.biz_type_id) === parseInt(this.state.bizTypeId)) {
                this.setState({
                    selectedBizType: data
                })
            }
            bizTypeTmp.push(data)
        }
        this.setState({
            categoryData: categoryTmp,
            bizTypeData: bizTypeTmp,
            showFilter: true
        })
    }

    closeSearchFilter = () => {
        console.log(this.props.businessTypes)
        this.setState({
            showFilter: false
        })
    }

    handleBizTypeChange = (selectedOption) => {
        this.setState({ selectedBizType: selectedOption });
    }

    handleCategoryChange = (selectedOption) => {
        this.setState({ selectedCategory: selectedOption });
    }

    searchFun = (e) => {
        if(e.key === 'Enter') {
            this.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
        }
    }

    searchFunClick = () => {
        this.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
    }

    render() {
        return (
            <div className="shop_home_container" style={{marginBottom: "80px"}}>

                <div className="app_bar ms_row border_bottom">
                    <div className="col_2">
                        <button onClick={() => this.props.history.goBack() }><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    </div>
                    <div className="search_section col_10">
                        <input 
                            ref={this.searchInput}
                            value={this.state.searchName} 
                            type="text" 
                            placeholder="Search" 
                            onFocus={this.showSearchFilter} 
                            onKeyPress={this.searchFun} 
                            onChange={e => this.handleInput('searchName', e)}
                        />
                        <div className="search-icon">
                            <i onClick={this.searchFunClick} className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                
                <div style={{ height: "40px", width: "100%" }}></div>

                {this.state.showFilter ?
                    <SearchFilter
                        show={this.state.showFilter}
                        bizTypeData={this.state.bizTypeData} 
                        selectedBizType={this.state.selectedBizType} 
                        handleBizTypeChange={this.handleBizTypeChange}
                        categoryData={this.state.categoryData}
                        selectedCategory={this.state.selectedCategory}
                        handleCategoryChange={this.handleCategoryChange}
                        closeSearchFilter={this.closeSearchFilter}
                    />
                : ""}

                {this.state.businessItemList && this.state.businessItemList.length > 0 ?
                    <InfiniteScroll
                        dataLength={this.state.businessItemList.length}
                        next={this.fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>No more items</b>
                            </p>
                        }
                    >
                        <div className="row pdt20" style={{ padding: 0, margin: 0 }}>
                            {this.state.businessItemList.map((product, index) =>
                                <div
                                className="col-6 col-md-3 col-lg-3"
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                >
                                    <Product businessId={product.business_id} shopId={product.shop_id} layout={this.state.layoutStyle} product={product} imgs={product.pic_or_color ? JSON.parse(product.pic_or_color) : ""} key={index} />
                                </div>
                            )}
                        </div>
                    </InfiniteScroll>
                    :
                        <div>
                            {this.state.notFound ? 
                                <p>No Item found!</p>
                            :
                            <Loader />
                            }
                        </div>
                    }

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        searchItems: state.searchItems,

        businessCategory: state.businessCategory,
        businessItem: state.businessItem,
        categorys: state.preDefinedCategories,
        businessTypes: state.businessTypes,
    }
}
export default connect(
    mapStateToProps, actions
)(ItemSearch)