import { combineReducers } from 'redux'
import businessCategoryReducer from './business.category.reducer'
import businessItemReducer from './business.item.reducer'
import preCategoryReducer from './pre.category.reducer'
import shoppingcartReducer from './shoppingcart.reducer'
import wishlistReducer from './wishlist.reducer'
import searchItemReducer from './search.item.reducer'
import businessTypeReducer from './business.type.reducer'
import itemDetailReducer from './item.detail.reducer'
import itemDetailByPropsReducer from './item.by.props.reducer'
import imageReducer from './image.reducer'

export default combineReducers({
    businessCategory: businessCategoryReducer,
    businessItem: businessItemReducer,
    preDefinedCategories: preCategoryReducer,
    shoppingCartCount: shoppingcartReducer,
    wishlistCount: wishlistReducer,

    searchItems: searchItemReducer,
    businessTypes: businessTypeReducer,

    itemDetail: itemDetailReducer,
    itemDetailByProps: itemDetailByPropsReducer,
    image: imageReducer
})