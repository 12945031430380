import { Card, CardBody, Collapse, ListGroup, ListGroupItem } from 'reactstrap'
import React from 'react'
import { deleteCollection, getShopCollections } from '../../api'
import Loader from '../../widgets/Loader'
import MetaForShare from '../../widgets/MetaForShare'
import { deleteShopFromCollection } from '../../api'

import './styles.css'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

class Collections extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            shopCollections: [],
            collapseId: "",
            notFound: false,
            notFoundMessage: ""
        }
    }

    componentDidMount() {
        this.refereshShopCollection()
    }

    refereshShopCollection = async () => {
        let shopCollections = await getShopCollections()

        if (shopCollections.code === '200') {
            this.setState({
                shopCollections: shopCollections.data,
                notFoundMessage: "",
                notFound: false
            })
        } else if(shopCollections.code === '403') {
            this.setState({
                notFound: true,
                notFoundMessage: "Log in to view collections"
            })
        } else {
            this.setState({
                notFound: true,
                notFoundMessage: shopCollections.message
            })
        }
    }

    toggle = (id) => {
        this.setState({
            collapseId: id
        })
    }

    deleteShopFromCollectionFun = async (cltId, shopId, businessId, shopName, cltName) => {
        if (window.confirm(`Are you sure you want to remove '${shopName}' shop from collection '${cltName}'?`)) {
            let delData = await deleteShopFromCollection(cltId, shopId, businessId)
            if(delData.code === '200') {
                this.refereshShopCollection()
                toast.success('Delete successful!')
            } else {
                toast.error(delData.message)
            }
        }
    }

    deleteCollectionFunc = async (cltId, cltName) => {
        if (window.confirm(`Are you sure you want to remove collection '${cltName}'?`)) {
            let delData = await deleteCollection(cltId)
            if(delData.code === '200') {
                this.refereshShopCollection()
                toast.success('Delete successful!')
            } else {
                toast.error(delData.message)
            }
        }
    }

    render() {
        return(
            
            <div style={{ marginBottom: "80px" }}>
                <MetaForShare
                    title="MMDP - Shop Detail"
                    url={`http://localhost:3000/${this.props.match.url}`}
                    img="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
                    desc="Shop Detail of the Glories"
                    siteName="Glories"
                />
                <div className="app_bar ms_row border_bottom">
                    <div className="col_2 t_left">
                        <button onClick={() => this.props.history.goBack()}><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <div className="search_section col_10">
                        <h2 style={{ fontSize: "22px" }}>Collections</h2>
                    </div>
                </div>

                <div style={{ height: "50px", width: "100%" }}></div>


                { !this.state.notFound ?
                    this.state.shopCollections && this.state.shopCollections.length > 0 ?
                        <div className='collection-wrapper'>
                            {this.state.shopCollections.map(shopCollections => (
                                <div className='collection' key={shopCollections.collection_id}>
                                    <div className='collected-name-wrapper'>
                                        <div className='collection-name'>{shopCollections.collection_name}</div>
                                        <span className='__icon collection-delete-icon'><i className="fa fa-trash-o" aria-hidden="true" onClick={e => this.deleteCollectionFunc(shopCollections.collection_id, shopCollections.collection_name)}></i></span>
                                        <span className='__icon show-collections' onClick={e => this.toggle(shopCollections.collection_id)}><i className="fa fa-sort-desc" aria-hidden="true"></i></span>
                                    </div>
                                    <div className='collection-shops'>
                                    {shopCollections.shops && shopCollections.shops.length > 0 ?
                                        shopCollections.shops.map(shops => (
                                            <Collapse key={shops.shop_id} className="collection-shop" isOpen={this.state.collapseId === shopCollections.collection_id}>
                                                <div className='collection-shop-detail'>
                                                    <div className='collection-shop-name'><Link to={`/mmdp/business/${shops.business_id}/${shops.shop_id}`} className="link-m"><span className="shop-header">{shops.shop_name}</span></Link></div>
                                                    <span className='__icon delete-icon'><i className="fa fa-minus-square-o" aria-hidden="true" onClick={e => this.deleteShopFromCollectionFun(shopCollections.collection_id, shops.shop_id, shops.business_id, shops.shop_name, shopCollections.collection_name)}></i></span>
                                                </div>
                                            </Collapse>
                                        ))
                                    : "" }
                                    </div>
                                </div>
                            ))}

                        </div>
                    : <Loader />
                    :
                    <div>
                        <div className="not-found">
                            <p className="not-found-letter">Empty here</p>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Collections