import React from 'react';

// Product
import './ItemListStyle.css';
import { Link } from 'react-router-dom';
import testImage from './images/luffyshoe.jpg';

class ItemListDesign extends React.Component {
  render() {
    return (
      <div className="bottom-space">
        <div className="app_bar ms_row border_bottom">
          <div className="col_2">
            <button onClick={() => this.props.history.goBack()}>
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="search_section col_10">
            <input
              ref={this.searchInput}
              type="text"
              placeholder="Search"
              onKeyPress={this.searchItems}
            />
            <div className="search-icon">
              <i
                onClick={this.searchItemsClick}
                className="fa fa-search"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          {/* <div className="group col_2">
                      <i className="fa fa-bars" aria-hidden="true" onClick={e => this.changeLayout("list")}></i>
                      <i className="fa fa-th" aria-hidden="true" onClick={e => this.changeLayout("grid")}></i>
                  </div> */}
        </div>

        <div style={{ height: '50px', width: '100%' }}></div>

        {/* <div
          className="shop_list_header"
          style={{
            lineHeight: '80px',
            maxHeight: '250px',
            textAlign: 'center',
            backgroundColor: '#B0BEC5',
          }}
        >
          <p
            style={{
              lineHeight: '1.5',
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: 0,

              fontSize: '1.8em',
              color: 'white',
              textShadow: `0 1px 0 #ccc,
           0 2px 0 #c9c9c9,
           0 3px 0 #bbb,
           0 4px 0 #b9b9b9,
           0 5px 0 #aaa,
           0 6px 1px rgba(0,0,0,.1),
           0 0 5px rgba(0,0,0,.1),
           0 1px 3px rgba(0,0,0,.3),
           0 3px 5px rgba(0,0,0,.2),
           0 5px 10px rgba(0,0,0,.25),
           0 10px 10px rgba(0,0,0,.2),
           0 20px 20px rgba(0,0,0,.15)`,
            }}
          >
            One Piece
          </p>
        </div> */}

        <div
          className="ms_row"
          style={{
            padding: '10px',
          }}
        >
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Product />
          </div>
        </div>
      </div>
    );
  }
}

export default ItemListDesign;

const Product = (props) => {
  return (
    <div
      className={`${props.layout === 'list' ? 'product_list' : 'item_list'}`}
      style={{ padding: 0 }}
    >
      <Link to={`/mmdp/productDetail/itemlist-design`}>
        <div className="product_inner">
          <div className={`${'product_head'}`}>
            <div className="product_image">
              <img src={testImage} alt="prodct" />
              {/* Discount */}
              <span className="discount">{10}% off</span>
            </div>
          </div>
          <div>
            <div className={`${'product_content'}`}>
              <h3 className="product_title">Luffy Shoe</h3>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'block',
                }}
              >
                One Piece
              </span>
              <span className="price">
                KS 10000
                {/* {props.product ? props.product.price_currency_code : ''}{' '}
                {props.product ? `${props.product.price} Ks` : ''} */}
              </span>
              {/* <span className="price">{props.product.category_name ? props.product.category_name : ""}</span> */}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
