import axios from 'axios';

// const BASE_URL = 'http://35.240.161.13:8079'
// const BASE_URL = 'https://www.mmdigitalplatform.com/glori1/'
const BASE_URL = 'https://dev-glori.mmdigitalplatform.com/api'
// const BASE_URL = 'http://128.199.200.199/api';

export const BusinessList = async () => {
  try {
    let businessList = await axios.get(`${BASE_URL}/shop/getBusinessList`);
    return businessList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Getting business list from server error!',
    };
  }
};

export const getBusinessTypes = async () => {
  try {
    let businessList = await axios.get(
      `${BASE_URL}/admin/getBusinessTypes4App`
    );
    return businessList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Getting business types from server error!',
    };
  }
};

export const getShopByBizType = async (bizTypeId) => {
  try {
    let shopList = await axios.get(
      `${BASE_URL}/shop/getShopsByBizType?bizTypeId=${bizTypeId}`
    );
    return shopList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Getting Shop For Biz Type from server error!',
    };
  }
};

export const getCategoryList = async (businessId) => {
  try {
    let categoriesFroBusiness = await axios.get(
      `${BASE_URL}/item/getAllCategories?businessId=${businessId}`
    );
    return categoriesFroBusiness.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Getting Categories List from server error!',
    };
  }
};

export const getItemList4Business = async (shopId, businessId) => {
  try {
    let itemsListFroBusiness = await axios.get(
      `${BASE_URL}/item/getItemList4Business?shopId=${shopId}&businessId=${businessId}`
    );
    return itemsListFroBusiness.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Gettting Item List for business server errror!',
    };
  }
};

export const getItemList4EndUser = async (
  shopId,
  businessId,
  categoryId,
  siteCategoryId,
  itemName,
  index,
  length
) => {
  try {
    // let itemsListFroBusiness = await axios.get(`${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}&categoryId=${categoryId}&siteCategoryId=${siteCategoryId}&itemName=${itemName}&index=${index}&length=${length}`)
    // return itemsListFroBusiness.data
    let itemList;
    if (itemName && siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}&itemName=${itemName}&siteCategoryId=${siteCategoryId}`
      );
    } else if (siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}&siteCategoryId=${siteCategoryId}`
      );
    } else if (index >= 0 && length > 0 && itemName) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}&index=${index}&length=${length}&itemName=${itemName}`
      );
    } else if (index >= 0 && length) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}&index=${index}&length=${length}`
      );
    } else {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4EndUser?shopId=${shopId}&businessId=${businessId}`
      );
    }
    return itemList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Gettting Item List for business server errror!',
    };
  }
};

export const getItemList4App = async (params) => {
  try {
    let { index, length, itemName, siteCategoryId } = params;
    let itemList;
    if (index && length && itemName && siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?index=${index}&length=${length}&itemName=${itemName}&siteCategoryId=${siteCategoryId}`
      );
    } else if (length && itemName && siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?length=${index}&itemName=${itemName}&siteCategoryId=${siteCategoryId}`
      );
    } else if (itemName && siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?itemName=${itemName}&siteCategoryId=${siteCategoryId}`
      );
    } else if (siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?siteCategoryId=${siteCategoryId}`
      );
    } else if (index && length) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?index=${index}&length=${length}`
      );
    } else if (index && length && siteCategoryId) {
      itemList = await axios.get(
        `${BASE_URL}/item/getItemList4App?index=${index}&length=${length}&siteCategoryId=${siteCategoryId}`
      );
    } else {
      itemList = await axios.get(`${BASE_URL}/item/getItemList4App`);
    }
    return itemList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Item for APP server errror!',
    };
  }
};

export const getPredefinedCategories = async () => {
  try {
    let preCategoriesList = await axios.get(
      `${BASE_URL}/item/get_site_category`
    );
    return preCategoriesList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Gettting Predefined category server errror!',
    };
  }
};

export const loginFacebook = async (token) => {
  try {
    let login = await axios.post(`${BASE_URL}/shop/addCustomer`, {
      token: token,
    });
    return login.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Login Facebook errror!',
    };
  }
};

export const getItemDetail = async (
  itemId,
  businessId,
  shopId,
  parentId = ''
) => {
  try {
    let itemDetails = await axios.get(
      `${BASE_URL}/item/getVariants4Item?itemId=${itemId}&businessId=${businessId}&shopId=${shopId}&parentId=${parentId}`
    );
    return itemDetails.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'getItemVariant server errror!',
    };
  }
};

export const searchItems = async (
  keyword,
  bizTypeId,
  siteCategoryId,
  section = '',
  index,
  length
) => {
  try {
    let items = [];
    if (index >= 0 && length > 0)
      items = await axios.get(
        `${BASE_URL}/item/searchItems?keyword=${keyword}&bizTypeId=${bizTypeId}&siteCategoryId=${siteCategoryId}&section=${section}&index=${index}&length=${length}`
      );
    else
      items = await axios.get(
        `${BASE_URL}/item/searchItems?keyword=${keyword}`
      );
    return items.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'searchItems server errror!',
    };
  }
};

export const searchShop = async (shopName, bizTypeId) => {
  try {
    let shops = await axios.get(
      `${BASE_URL}/shop/getShopsByBizType?shopName=${shopName}&bizTypeId=${bizTypeId}`
    );
    return shops.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'searchItems server errror!',
    };
  }
};

export const getCustomerDetail = async (token) => {
  try {
    let authAxios2;
    if (token) {
      authAxios2 = axios.create({
        headers: {
          authorization: token,
        },
      });
    } else {
      authAxios2 = axios.create({
        headers: {
          authorization: `${localStorage.getItem('app_token')}`,
        },
      });
    }
    let customerDetail = await authAxios2.get(
      `${BASE_URL}/shop/getCustomerDetails`
    );
    return customerDetail.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Customer Detail errror!',
    };
  }
};

// let authAxios = axios.create({
//     headers: {
//         "authorization": `${localStorage.getItem("app_token")}`
//     }
// });

export const editCustomerInfo = async (
  customerName,
  email,
  phone,
  addrName,
  addrValue
) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let customerDetail = await authAxios.post(`${BASE_URL}/shop/editCustomer`, {
      customerName,
      email,
      phone,
      addrName,
      addrValue,
    });
    return customerDetail.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Edit Customer Detail errror!',
    };
  }
};

export const getSaleList = async () => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let orderList = await authAxios.get(
      `${BASE_URL}/order/getSaleListByCustomer`
    );
    return orderList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Order Detail errror!',
    };
  }
};

export const checkLogin = async () => {
  try {
    let customerDetail = await getCustomerDetail();
    if (customerDetail.code === '200') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getOrderOptions = async () => {
  try {
    let orderOptions = await axios.get(
      `${BASE_URL}/shop/getOrderOptions?shopId=${localStorage.getItem(
        'shop_id'
      )}&businessId=${localStorage.getItem('businessId')}`
    );
    return orderOptions.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Order Options errror!',
    };
  }
};

export const addPosSale = async (
  shopId,
  businessId,
  paymentMethod,
  diningOption,
  orderItems,
  deliAddress,
  phone
) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let add = await authAxios.post(`${BASE_URL}/order/addPosSale`, {
      shopId,
      businessId,
      paymentMethod,
      diningOption,
      orderItems,
      phone,
      deliAddress,
    });
    return add.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Add Sale errror!',
    };
  }
};

export const confirmPayment = async (saleNo, shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let add = await authAxios.post(`${BASE_URL}/order/confirmPayment`, {
      saleNo,
      shopId,
      businessId,
    });
    return add.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'confirmPayment errror!',
    };
  }
};

export const getOrderDetail = async (saleNo, shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let orderDetail = await authAxios.get(
      `${BASE_URL}/order/getSaleDetails4Mail?saleNo=${saleNo}&shopId=${shopId}&businessId=${businessId}`
    );
    return orderDetail.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Order Detail error!',
    };
  }
};

export const getShopByCoordinate = async (lat, lng) => {
  try {
    let shops = await axios.get(
      `${BASE_URL}/shop/getShopByCoordinates?lat=${lat}&lon=${lng}&dist=15&numOfShop=100`
    );
    return shops.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Shop by Coordinate error!',
    };
  }
};

export const getCustomerAddressList = async () => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let addList = await authAxios.get(`${BASE_URL}/shop/getCustomerAddrList`);
    return addList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Get Shop Address error!',
    };
  }
};

export const addCustomerAddress = async (addrName, addrValue) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let addList = await authAxios.post(`${BASE_URL}/shop/addCustomerAddr`, {
      addrName,
      addrValue,
    });
    return addList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Add Shop Address error!',
    };
  }
};

export const deleteCustomerAddress = async (addrName) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let addList = await authAxios.post(`${BASE_URL}/shop/deleteCustomerAddr`, {
      addrName,
    });
    return addList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Delete Shop Address error!',
    };
  }
};

export const setDefaultCustomerAddress = async (addrName) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let addList = await authAxios.post(`${BASE_URL}/shop/setDefaultAddr`, {
      addrName,
    });
    return addList.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Set Default Shop Address error!',
    };
  }
};

export const requestOtp = async (phone) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.get(
      `${BASE_URL}/shop/requestOtp?phone=${phone}`
    );
    console.log(res);
    return res.data;
  } catch (error) {
    console.error('requestOtp => ', error);
  }
};

export const verifyOtp = async (requestId, code, phone) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.get(
      `${BASE_URL}/shop/verifyOtp4EndUser?requestId=${requestId}&code=${code}&phone=${phone}`
    );
    return res.data;
  } catch (error) {
    console.error('requestOtp => ', error);
  }
};

export const getShopDetails = async (shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.get(
      `${BASE_URL}/shop/getShopDetails?shopId=${shopId}&businessId=${businessId}&customerId=${localStorage.getItem(
        'customerId'
      )}`
    );
    return res.data;
  } catch (error) {
    console.error('getShopDetails => ', error);
  }
};

export const getShopCollections = async (shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.get(`${BASE_URL}/crm/getShopCollections`);
    return res.data;
  } catch (error) {
    console.error('getShopCollections => ', error);
  }
};

export const getCollectionList = async (shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.get(`${BASE_URL}/crm/getCollectionList`);
    return res.data;
  } catch (error) {
    console.error('getCollectionList => ', error);
  }
};

export const addShopToCollection = async (
  cltId = '',
  cltName = '',
  shopId,
  businessId
) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.post(`${BASE_URL}/crm/addShopToCollection`, {
      cltId,
      cltName,
      shopId,
      businessId,
    });
    return res.data;
  } catch (error) {
    console.error('getCollectionList => ', error);
  }
};

export const deleteShopFromCollection = async (cltId, shopId, businessId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.post(
      `${BASE_URL}/crm/deleteShopFromCollection`,
      { cltId, shopId, businessId }
    );
    return res.data;
  } catch (error) {
    console.error('deleteShopFromCollection => ', error);
  }
};

export const deleteCollection = async (cltId) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    const res = await authAxios.post(`${BASE_URL}/crm/deleteCollection`, {
      cltId,
    });
    return res.data;
  } catch (error) {
    console.error('deleteCollection => ', error);
  }
};

export const findItemByProps = async (
  pid,
  itemId,
  pCount,
  parentId,
  shopId,
  businessId
) => {
  try {
    let itemByProps = await axios.post(`${BASE_URL}/item/findItemByProps`, {
      pid,
      itemId,
      pCount,
      parentId,
      shopId,
      businessId,
    });
    return itemByProps.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Gettting Predefined category server errror!',
    };
  }
};

export const addCustomerProfileImg = async (base64Img) => {
  try {
    let authAxios = axios.create({
      headers: {
        authorization: `${localStorage.getItem('app_token')}`,
      },
    });
    let uploadImage = await authAxios.post(
      `${BASE_URL}/shop/addCustomerProfileImg`,
      { base64Img }
    );
    return uploadImage.data;
  } catch (error) {
    console.error(error);
    return {
      code: 500,
      message: 'Upload Image server errror!',
    };
  }
};
