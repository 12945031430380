import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';

import './styles.css'
import * as actions from '../../actions'
import { Link } from 'react-router-dom';
import ShopProfile from '../../widgets/ShopProfile';
import Product from '../../widgets/ProductMiniDetail'
import Loader from '../../widgets/Loader';
import SearchFilter from '../../widgets/SearchFilter';

class Search extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            itemName: "",
            searchName: "",
            bizTypeId: "",
            categoryId: "",
            index: 0,
            length: 4,
            
            shopList: [],
            itemList: [],
            notFound: false,
            noItems: false,
            noShops: false,

            showFilter: false,
            categoryData: [],
            bizTypeData: [],
            selectedBizType: {
                value: "",
                label: "Choose Business Type"
            },
            selectedCategory: {
                value: "",
                label: "Choose Categroy"
            },
        }
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search)
        let itemName = query.itemName
        let bizTypeId = query.bizTypeId
        let siteCategoryId = query.siteCategoryId
        this.setState({
            searchName: itemName,
            bizTypeId: bizTypeId,
            categoryId: siteCategoryId
        })
        this.props.searchItemsAction(itemName, bizTypeId, siteCategoryId, "", this.state.index, this.state.length)
        if(!this.props.categorys.code) {
            this.props.getPreCategories()
        }
        if(!this.props.businessTypes.code) {
            this.props.getBusinessTypesForApp()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.searchItems !== nextProps.searchItems) {
            if(nextProps.searchItems.code === '200') {
                if(nextProps.searchItems.data) {
                    if(nextProps.searchItems.data.items) {
                        if(nextProps.searchItems.data.items.length > 0) {
                            this.setState({
                                itemList: nextProps.searchItems.data.items,
                                notFound: false,
                                noItems: false,
                            })
                        } else {
                            this.setState({
                                itemList: [],
                                noItems: true,
                                notFound: false,
                            })
                        }
                    } else {
                        this.setState({
                            itemList: [],
                            noItems: true,
                            notFound: false,
                        })
                    }

                    
                    if(nextProps.searchItems.data.shops) {
                        if(nextProps.searchItems.data.shops.length > 0) {
                            this.setState({
                                shopList: nextProps.searchItems.data.shops,
                                notFound: false,
                                noShops: false,
                            })
                        } else {                            
                            this.setState({
                                shopList: [],
                                noShops: true,
                                notFound: false,
                            })
                        }
                    } else {
                        this.setState({
                            shopList: [],
                            noShops: true,
                            notFound: false,
                        })
                    }
                } else {
                    this.setState({
                        itemList: [],
                        notFound: true
                    })
                }
            } else {
                this.setState({
                    notFound: true
                })
            }
        }
    }

    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value,
            showFilter: true
        })
    }

    showSearchFilter = () => {
        let categoryTmp = [{
            value: "",
            label: "Choose Categroy"
        }]
        let bizTypeTmp = [{
            value: "",
            label: "Choose Business Type"
        }]

        for(let category of this.props.categorys.data) {
            let data = {
                value: category.site_category_id,
                label: category.site_category_name
            }
            if(parseInt(category.site_category_id) === parseInt(this.state.categoryId)) {
                this.setState({
                    selectedCategory: data
                })
            }
            categoryTmp.push(data)
        }

        for(let bizType of this.props.businessTypes.data) {
            let data = {
                value: bizType.biz_type_id,
                label: bizType.biz_type_name
            }
            if(parseInt(bizType.biz_type_id) === parseInt(this.state.bizTypeId)) {
                this.setState({
                    selectedBizType: data
                })
            }
            bizTypeTmp.push(data)
        }
        this.setState({
            categoryData: categoryTmp,
            bizTypeData: bizTypeTmp,
            showFilter: true
        })
    }

    closeSearchFilter = () => {
        this.setState({
            showFilter: false
        })
    }

    handleBizTypeChange = (selectedOption) => {
        this.setState({ 
            selectedBizType: selectedOption,
            bizTypeId: selectedOption.value
        });
    }

    handleCategoryChange = (selectedOption) => {
        this.setState({ 
            selectedCategory: selectedOption,
            categoryId: selectedOption.value
        });
    }

    handleSearch = (e) => {
        if(e.key === 'Enter') {
            this.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
            this.setState({
                itemList: [],
                shopList: [],
                showFilter: false
            })
            this.props.searchItemsAction(this.state.searchName, this.state.selectedBizType.value, this.state.selectedCategory.value, "", this.state.index, this.state.length)
        }
    }
    
    handleSearchClick = () => {
        this.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
        this.setState({
            itemList: [],
            shopList: []
        })
        this.props.searchItemsAction(this.state.searchName, this.state.selectedBizType.value, this.state.selectedCategory.value, "", this.state.index, this.state.length)
    }
    
    render() {
        return(
            <div className="search_container" style={{marginBottom: "80px"}}>
                
                <div className="app_bar ms_row border_bottom">
                    <div className="col_2">
                        <button><Link to="/mmdp"><i className="fa fa-chevron-left" aria-hidden="true"></i></Link></button>
                    </div>
                    <div className="search_section col_10">
                        <input 
                            ref={this.searchInput} 
                            value={this.state.searchName}
                            type="text" 
                            placeholder="Search" 
                            onFocus={this.showSearchFilter} 
                            onKeyPress={this.handleSearch} 
                            onChange={e => this.handleInput('searchName', e)}
                        />
                        <div className="search-icon">
                            <i onClick={this.handleSearchClick} className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div style={{ height: "40px", width: "100%" }}></div>

                {this.state.showFilter ?
                    <SearchFilter
                        show={this.state.showFilter}
                        bizTypeData={this.state.bizTypeData} 
                        selectedBizType={this.state.selectedBizType} 
                        handleBizTypeChange={this.handleBizTypeChange}
                        categoryData={this.state.categoryData}
                        selectedCategory={this.state.selectedCategory}
                        handleCategoryChange={this.handleCategoryChange}
                        closeSearchFilter={this.closeSearchFilter}
                    />
                : ""}

                {this.state.notFound || (this.state.noItems && this.state.noShops) ?
                    <div className="not-found">
                        <p className="not-found-letter">No Search Result!</p>
                    </div>
                :
                <div className="pdt20">
                    {this.state.noShops ?
                        ""
                    :
                    <div className="mini_shop_list">
                        <div className="list_head">
                            <h2>Shops</h2>
                            <Link to={`/mmdp/shopSearch?itemName=${this.state.searchName}&bizTypeId=${this.state.bizTypeId}&siteCategoryId=${this.state.categoryId}`}>More...</Link>
                        </div>
                        <div className="contents row">
                            {this.state.shopList && this.state.shopList.length > 0 ?
                                this.state.shopList.map(shop => (
                                    <div
                                      className="col-6 col-md-3 col-lg-2"
                                      key={`${shop.business_id}${shop.shop_name}`}
                                      style={{ paddingRight: 0, paddingLeft: 0 }}
                                    >
                                        <ShopProfile shop={shop} />
                                    </div>
                                ))

                            : <Loader />}
                        </div>
                    </div>
                    }
                    {this.state.noItems ? "" :
                        <div className="mini_item_list">
                            <div className="list_head">
                                <h2>Items</h2>
                                <Link to={`/mmdp/itemSearch?itemName=${this.state.searchName}&bizTypeId=${this.state.bizTypeId}&siteCategoryId=${this.state.categoryId}`}>More...</Link>
                            </div>
                            
                            <div className="contents row">
                                {this.state.itemList && this.state.itemList.length > 0 ?
                                    this.state.itemList.map(item => (
                                        <div
                                          className="col-6 col-md-3 col-lg-3"
                                          style={{ paddingRight: 0, paddingLeft: 0 }}
                                        >
                                        <Product businessId={item.business_id} shopId={item.shop_id} layout={"grid"} product={item} imgs={item.pic_or_color ? JSON.parse(item.pic_or_color) : ""} key={item.item_id} />
                                        </div>
                                    ))

                                : <Loader />}
                            </div>
                        </div>
                    }
                </div>    
                }                
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        searchItems: state.searchItems,
        businessItem: state.businessItem,
        categorys: state.preDefinedCategories,
        businessTypes: state.businessTypes,
    }
}
export default connect(
    mapStateToProps, actions
)(Search)