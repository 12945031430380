import React from 'react'

import './styles.css'
import MetaForShare from '../../widgets/MetaForShare'

const Contact = (props) => {

    return (
        <div className="container contact_container">

            <MetaForShare 
                title="MMDP - Contact Us" 
                url={`http://localhost:3000/${props.match.url}`}
                img="https://previews.123rf.com/images/val2014/val20141603/val2014160300005/54302312-shopping-cart-icon.jpg"
                desc="Contact Us of the Glories"
                siteName="Glories"
            />

            <div className="col-sm-12">
                <div className="section-title text-center mb-2">
                    <h2 className="title">Get in Touch With Us</h2>
                    <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                </div>
            </div>

            <div className="row contact_summery_container">
                <div className="col-sm-12 col-md-4">
                    <div className="contact">
                        <div className="icon_inner">
                            <i className="fa fa-map-marker"></i>
                        </div>
                        <div className="box_contact">
                            <h6>Location: </h6>
                            <p>sffaffaf sfasfaf fadfafsdfsafa asfasfsafafaf</p>
                        </div>
                    </div>
                </div>


                <div className="col-sm-12 col-md-4">
                    <div className="contact">
                        <div className="icon_inner">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="box_contact">
                            <h6>Phone: </h6>
                            <p>123 457 8901</p>
                        </div>
                    </div>
                </div>


                <div className="col-sm-12 col-md-4">
                    <div className="contact">
                        <div className="icon_inner">
                            <i className="fa fa-envelope-o"></i>
                        </div>
                        <div className="box_contact">
                            <h6>Mail: </h6>
                            <p>test@test.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact