import React from 'react';

import './styles.css';
import { Link } from 'react-router-dom';
import luffyShoe from './images/luffyshoe.jpg';
import zoroShoe from './images/zoro-shoe.webp';
import sanjiShoe from './images/sanji-shoe.jpg';
import namiShoe from './images/nami-shoe.jpg';

class ShopList extends React.Component {
  render() {
    return (
      <div className="bottom-space">
        <div
          className="ms_row"
          style={{
            padding: '10px',
          }}
        >
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: luffyShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: zoroShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: sanjiShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: namiShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: luffyShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: luffyShoe }} />
          </div>
          <div
            className="col-6 col-md-3 col-lg-2"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <TestShopProfile shop={{ shop_img_url: luffyShoe }} />
          </div>
        </div>
      </div>
    );
  }
}

export default ShopList;

const TestShopProfile = (props) => {
  const { shop } = props;
  return (
    <div className="shop_profile">
      <div className="card">
        <Link className="img-container" to={`/mmdp/testing`}>
          <img
            className="card-img-top"
            src={shop.shop_img_url}
            alt="shop prodct"
          />
        </Link>
        <div className="card-body t_center">
          <Link to={`/mmdp/testing`}>
            <h5 className="card-title shop_title t_left">
              Luffy Shoe Luffy ShoeLuffy ShoeLuffy Shoe
            </h5>
          </Link>
          <span style={{ width: '90%' }}>
            <div className="t_left">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span
                className="fa fa-star-half-o checked"
                aria-hidden="true"
              ></span>
              <span className="fa fa-star"></span>
            </div>
          </span>
          <Link to={`/mmdp/testing`}>
            <span
              style={{ width: '10%', fontSize: '1.3em', cursor: 'pointer' }}
              onClick={() => console.log('click')}
            >
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
