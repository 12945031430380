import React from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux'

import './MenuStyles.css'
import * as actions from '../../actions'
import MiniCart from '../../widgets/MiniCartList'
// import MobileNav from '../../widgets/MobileNav'
import Logo from '../../assets/img/MMDP.png'

class MenuBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeCart: '',
            navActive: '',
            preCategories: [],
            cartData: 0,
            wishlist: 0
        }
    }

    componentDidMount() {
        // this.props.getPreCategories()
        this.props.getWishListCount()
        this.props.getShoppingCartCount()
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.preDefinedCategories !== nextProps.preDefinedCategories) {
            this.setState({
                preCategories: nextProps.preDefinedCategories.data
            })
        }

        if(this.props.wishlistCount !== nextProps.wishlistCount) {
            this.setState({
                wishlist: nextProps.wishlistCount.count
            })
        }

        if(this.props.shoppingCartCount !== nextProps.shoppingCartCount) {
            this.setState({
                cartData: nextProps.shoppingCartCount.count
            })
        }
    }

    // showMiniCart = () => {
    //     this.setState({
    //         activeCart: "active"
    //     })
    // }

    // closeMiniCart = () => {
    //     this.setState({
    //         activeCart: ""
    //     })
    // }

    showMobileNav = () => {
        this.setState({
            navActive: 'active'
        })
    }

    closeMobileNav = () => {
        this.setState({
            navActive: ''
        })
    }

    render() {
        return(
            <React.Fragment>
                <ToastContainer className="zInd" autoClose={1500} />
                <header className="headerStyle">
                    <div className="container">
                        <div className="headerLeft">
                            <div className="siteBranding">
                                <Link className="customLogoLink" to="/mmdp">
                                    <div className="header-logo">
                                        <img src={Logo} alt="MMDP LOGO" />
                                    </div>
                                    <span className='header-text'>
                                            Myanmar Digital Platform
                                    </span>
                                </Link>
                            </div>
                            <div className="siteHeaderCartMobile">
                                <span className="cartContents laptop" title="View your Wish List">
                                    <span><Link to="/mmdp/profile"><i className="fa fa-user-o menuIcon" aria-hidden="true"></i></Link></span>
                                </span>

                                <span className="cartContents" title="View your shopping cart">
                                    <span><Link to="/mmdp/shoppingcart"><i className="fa fa-shopping-cart menuIcon shopping_cart" aria-hidden="true"></i></Link></span>
                                    <span className="count">{this.state.cartData}</span>
                                </span>
                            </div>

                            {/* <span className="menuMobileNavButton" onClick={this.showMobileNav}>
                                <i className="fa fa-bars menuIcon" aria-hidden="true"></i>
                            </span> */}
                        </div>
                    </div>
                </header>

                {/* <MobileNav preCategories={this.state.preCategories} closeMobileNav={this.closeMobileNav} navActive={this.state.navActive} /> */}

                <MiniCart closeMiniCart={this.closeMiniCart} activeCart={this.state.activeCart} />

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        preDefinedCategories: state.preDefinedCategories,
        wishlistCount: state.wishlistCount,
        shoppingCartCount: state.shoppingCartCount
    }
}

export default connect(
    mapStateToProps, actions
)(MenuBar)