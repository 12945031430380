export const GET_CATEGORY_FOR_BUSINESS = "GET_CATEGORY_FOR_BUSINESS"
export const GET_ITEM_FOR_BUSINESS = "GET_ITEM_FOR_BUSINESS"
export const GET_ITEM_LIST_FOR_SITE_CATEGORY = "GET_ITEM_LIST_FOR_SITE_CATEGORY"
export const GET_PRE_DEFINED_CATEGORIES = "GET_PRE_DEFINED_CATEGORIES"
export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE"
export const SEARCH_ITEMS = "SEARCH_ITEMS"

export const SHOPPING_CART_COUNT = "SHOPPING_CART_COUNT"
export const WISHLIST_COUNT = "WISHLIST_COUNT"

export const GET_ITEM_VARIANT = "GET_ITEM_VARIANT"
export const FIND_ITEM_BY_PROPS = "FIND_ITEM_BY_PROPS"

export const EDIT_IMAGE = "EDIT_IMAGE"