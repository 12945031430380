/**
 *  Shop Cart Detail Page
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Table } from 'reactstrap';
import { connect } from 'react-redux'

import './styles.css'
import * as actions from '../../actions'
import MetaForShare from '../../widgets/MetaForShare'
import noImage from '../../assets/img/no-image.jpg'

class ShopingCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ShippingFlatRate: 1.50,
            ShippingLocalPickUp: 2.00,
            TotalShippingCarge: 1.50,
            Adress1: "B-87,Erceru erpopnt",
            Adress2: "Arndurm",
            Adress3: "UK"
        }

        this.ReadCartItems = this.ReadCartItems.bind(this);
        this.PlusQty = this.PlusQty.bind(this);
        this.MinusQty = this.MinusQty.bind(this);
        this.SetDefaults = this.SetDefaults.bind(this);
    }

    componentDidMount() {
        this.SetDefaults(document, 'script');
        var evt = document.createEvent('Event');
        evt.initEvent('load', false, false);
        window.dispatchEvent(evt);
        window.scrollTo(0, 0);
    }


    SetDefaults() {

        // var cart = JSON.parse(localStorage.getItem("cart_item"));


        // if (cart.length === 0) {
        //     this.props.history.push(`/`)
        //     return;
        // }
        this.forceUpdate();

    }

    calculateSubTotal = () => {
        let cartItems = JSON.parse(localStorage.getItem("cart_item"));

        let subToatl = 0.00
        for(let item of cartItems) {
            if(item.variants.length > 0) {
                subToatl += item.qty * parseFloat(item.item_price)
                for(let variant of item.variants) {
                    subToatl += parseFloat(variant.price) * item.qty
                }
            } else {
                subToatl += item.qty * parseFloat(item.item_price)
            }
        }
        return subToatl.toFixed(2)
    }

    calculateDiscount = () => {
        let cartItems = JSON.parse(localStorage.getItem("cart_item"));

        let discount = 0.00
        for(let item of cartItems) {
            discount += parseFloat(item.item_price) * parseInt(item.qty) * parseFloat(item.discount)/100
        }
        return discount.toFixed(2)
    }

    
    calculateTax = () => {
        let cartItems = JSON.parse(localStorage.getItem("cart_item"));
        
        let tax = 0.00
        let discount = 0.00
        for(let item of cartItems) {
            discount = parseFloat(item.item_price) * parseInt(item.qty) * parseFloat(item.discount)/100
            if(item.variants) {
                let varaintPrice = 0.00
                for(let variant of item.variants) {
                    varaintPrice += parseFloat(variant.price)
                }
                tax += ((parseFloat(item.item_price) * parseInt(item.qty)) + (parseFloat(varaintPrice) * parseInt(item.qty)) - discount) * parseFloat(item.tax) / 100
            } else tax += (parseFloat(item.item_price) * parseInt(item.qty)) * parseFloat(item.tax)/100
        }
        return tax.toFixed(2)
    }

    calculateItemToatal = (item) => {
        let subToatl = 0.00
        if(item.variants.length > 0) {
            subToatl += item.qty * parseFloat(item.item_price)
            for(let variant of item.variants) {
                subToatl += parseFloat(variant.price) * item.qty
            }
        } else {
            subToatl += item.qty * parseFloat(item.item_price)
        }
        return subToatl.toFixed(2)
    }

    ReadCartItems() {
        return JSON.parse(localStorage.getItem("cart_item"));
    }

    removeFromCart = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("cart_item"));
        UpdatedCart = UpdatedCart.slice(0, Index).concat(UpdatedCart.slice(Index + 1, UpdatedCart.length));
        if(!UpdatedCart.length > 0) {
            localStorage.removeItem("shop_id")
            localStorage.removeItem("businessId")
        }
        localStorage.removeItem("cart_item");
        localStorage.setItem("cart_item", JSON.stringify(UpdatedCart));
        this.props.getShoppingCartCount()
    }

    PlusQty = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("cart_item"));
        UpdatedCart[Index].qty = parseInt(UpdatedCart[Index].qty + 1);
        localStorage.removeItem("cart_item");
        localStorage.setItem("cart_item", JSON.stringify(UpdatedCart));
    }

    MinusQty = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("cart_item"));

        if (UpdatedCart[Index].qty !== 1) {

            UpdatedCart[Index].qty = parseInt(UpdatedCart[Index].qty - 1);
            localStorage.removeItem("cart_item");
            localStorage.setItem("cart_item", JSON.stringify(UpdatedCart));
        } else {
            this.removeFromCart(Index);
        }
    }

    render() {

        return (
            <div className="site-content">
                <MetaForShare 
                    title="MMDP - Shopping Cart" 
                    url={`http://localhost:3000/${this.props.match.url}`}
                    img="https://previews.123rf.com/images/val2014/val20141603/val2014160300005/54302312-shopping-cart-icon.jpg"
                    desc="Shop Cart of the Glories"
                    siteName="Glories"
                />
                <div className="content-wrapper section-ptb">
                        {(this.ReadCartItems() != null && this.ReadCartItems().length > 0) ?

<Container>
                            <Row className="w_100" style={{marginBottom: "80px"}}>
                                <Col md={12}>
                                    <div className="table-responsive">
                                        <Table className="cart-table">
                                            <thead>
                                                <tr>
                                                    <th clas="product-remove"></th>
                                                    <th className="product-thumbnail"></th>
                                                    <th className="product-name">
                                                        <span className="nobr">Product</span>
                                                    </th>
                                                    <th className="product-price">
                                                        <span className="nobr">
                                                            Price </span>
                                                    </th>
                                                    <th className="product-variants">
                                                        <span className="nobr">
                                                            Variants </span>
                                                    </th>
                                                    <th className="product-stock-status">
                                                        Quantity
                                                    </th>
                                                    <th className="product-subtotal">Total</th>
                                                </tr>

                                                {this.ReadCartItems().map((CartItem, index) => (
                                                    <tr>
                                                        <td className="product-remove">
                                                            <Link onClick={() => this.removeFromCart(index)} className="remove"></Link>
                                                        </td>
                                                        <td className="res product-thumbnail">
                                                            <Link to="#">
                                                                {CartItem.photo && JSON.parse(CartItem.photo).length > 0 ?
                                                                    <img src={JSON.parse(CartItem.photo)[0]} alt="product" />
                                                                : 
                                                                    <img src={noImage} alt="prodct" />
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td className="res product-name">
                                                            {CartItem.item_name}
                                                        </td>
                                                        <td className="res product-price" data-title="Price">
                                                            {CartItem.item_price} Ks
                                                        </td>
                                                        
                                                        
                                                        <td className={`res product-variant ${CartItem.variants && !CartItem.variants.length > 0 && CartItem.properties && !CartItem.properties.length > 0 ? "no-variant" : ""}`} >
                                                            {CartItem.variants && CartItem.variants.length > 0 ?
                                                                CartItem.variants.map(variant => (
                                                                    <span className="variants" key={`${variant.variantId}_${variant.choiceName}_${variant.price}`}>
                                                                        {/* <span className="left name">{variant.variantName}</span> */}
                                                                        <span className="left">{variant.choiceName}</span>
                                                                        <span>{variant.price} Ks</span>
                                                                    </span>
                                                                ))

                                                            : ""
                                                            }
                                                            {CartItem.properties && CartItem.properties.length > 0 ?
                                                                CartItem.properties.map(property => (
                                                                    <span className="variants" key={`${property}`}>
                                                                        <span className="left">{property}</span>
                                                                        <span></span>
                                                                    </span>
                                                                ))

                                                            : ""
                                                            }
                                                            {CartItem.note &&
                                                                <span className="special-ins">
                                                                    {/* <span className="left name">{variant.variantName}</span> */}
                                                                    <span className="left">{CartItem.note}</span>
                                                                    <span></span>
                                                                </span>
                                                            }
                                                        </td>
                                                        <td className="res product-quantity" data-title="Quantity">
                                                            <div className="quantity">
                                                                <label className="screen-reader-text" htmlFor="quantity_5cd96a418e8ad">Quantity</label>
                                                                <input type="number" className="input-text qty text" value={CartItem.qty} title="Qty" />
                                                                <div className="quantity-nav">
                                                                    <Link className="quantity-button quantity-up" onClick={() => this.PlusQty(index)} >+</Link>
                                                                    <Link className="quantity-button quantity-down" onClick={() => this.MinusQty(index)} >-</Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="res product-subtotal" data-title="Amount">
                                                            {this.calculateItemToatal(CartItem)} Ks
                                                        </td>
                                                    </tr>

                                                ))}
                                            </thead>
                                        </Table>
                                    </div>
                                </Col>
                                <div className="cart-collaterals col-md-12">
                                    <div className="cart_totals ">
                                        <h2>Cart totals</h2>
                                        <div className="table-responsive">
                                            <Table cellSpacing="0" className="shop_table shop_table_responsive">
                                                <tbody>
                                                    <tr className="cart-subtotal">
                                                        <th>Subtotal</th>
                                                        <td data-title="Subtotal">
                                                            <span className="woocs_special_price_code">
                                                                <span className="amount">
                                                                    {this.calculateSubTotal()} Ks
                                                                    {/* {this.ReadCartItems().reduce((fr, CartItem) => ( CartItem.variants.length > 0 ? CartItem.variants.reduce((fr2, variant) => parseFloat(fr2) + parseFloat(variant.price)) : 0), 0)}   */}
                                                                </span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart-subtotal">
                                                        <th>Discount</th>
                                                        <td data-title="Subtotal">
                                                            <span className="woocs_special_price_code">
                                                                <span className="amount">
                                                                    {this.calculateDiscount()} Ks
                                                                    {/* {this.ReadCartItems().reduce((fr, CartItem) => ( CartItem.variants.length > 0 ? CartItem.variants.reduce((fr2, variant) => parseFloat(fr2) + parseFloat(variant.price)) : 0), 0)}   */}
                                                                </span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart-subtotal">
                                                        <th>Tax</th>
                                                        <td data-title="Subtotal">
                                                            <span className="woocs_special_price_code">
                                                                <span className="amount">
                                                                    {this.calculateTax()} Ks
                                                                    {/* {this.ReadCartItems().reduce((fr, CartItem) => ( CartItem.variants.length > 0 ? CartItem.variants.reduce((fr2, variant) => parseFloat(fr2) + parseFloat(variant.price)) : 0), 0)}   */}
                                                                </span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <th>Total</th>
                                                        <td data-title="Total">
                                                            <strong>
                                                                <span className="special_price_code">
                                                                    <span className="amount">
                                                                        {(parseFloat(this.calculateSubTotal()) - parseFloat(this.calculateDiscount()) + parseFloat(this.calculateTax())).toFixed(2)} Ks
                                                                    </span>
                                                                </span>
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="proceed-to-checkout">
                                            <Link to="/mmdp/checkout" className="checkout-button button">
                                                Proceed to checkout</Link>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                    </Container>
                            :
                            <div className="not-found" style={{top: "40%"}}>
                                <h4 className="d-block">Your cart is empty!</h4>
                                <Link to="/mmdp" className="btn btn-solid">Continue Shopping</Link>
                            </div>
                        }
                </div>


            </div>



        )
    }
}

function mapStateToProps(state) {
    return {
        shoppingCartCount: state.shoppingCartCount
    }
}

export default connect(
    mapStateToProps, actions
)(ShopingCart)