import React from 'react'

import './styles.css'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import SearchFilter from '../SearchFilter'

class AboutBanner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            showFilter: false,
            categoryData: [],
            bizTypeData: [],
            selectedBizType: {
                value: "",
                label: "Choose Business Type"
            },
            selectedCategory: {
                value: "",
                label: "Choose Categroy"
            },
            searchName: ""
        }
        this.searchInput = React.createRef();
        this.searchInput2 = React.createRef();
    }

    componentDidMount() {
        if(!this.props.categorys.code) {
            this.props.getPreCategories()
        }
    }

    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    showSearchFilter = () => {
        let categoryTmp = [{
            value: "",
            label: "Choose Categroy"
        }]
        
        let bizTypeTmp = [{
            value: "",
            label: "Choose Business Type"
        }]

        for(let category of this.props.categorys.data) {
            let data = {
                value: category.site_category_id,
                label: category.site_category_name
            }
            categoryTmp.push(data)
        }

        for(let category of this.props.businessTypes.data) {
            let data = {
                value: category.biz_type_id,
                label: category.biz_type_name
            }
            bizTypeTmp.push(data)
        }
        this.setState({
            categoryData: categoryTmp,
            bizTypeData: bizTypeTmp,
            showFilter: true
        })
    }

    closeSearchFilter = () => {
        this.setState({
            showFilter: false
        })
    }

    handleBizTypeChange = (selectedOption) => {
        this.setState({ selectedBizType: selectedOption });
    }

    handleCategoryChange = (selectedOption) => {
        this.setState({ selectedCategory: selectedOption });
    }

    searchFun = (e) => {
        if(e.key === 'Enter') {
            this.props.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
        }
    }

    searchFunClick = () => {
        this.props.props.history.push(`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`)
    }

    render() {
        return(
            <div className="about_container">
                <div className="image_session">
                    {/* <img src={banner} alt="Banner " /> */}
                </div>
                <div className="search_section">
                    <input 
                        ref={this.searchInput} 
                        type="text" 
                        placeholder="Search" 
                        onFocus={this.showSearchFilter} 
                        onKeyPress={this.searchFun} 
                        onChange={e => this.handleInput('searchName', e)}
                    />
                    <div className="search-icon">
                        <i onClick={this.searchFunClick} className="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
                
                {this.state.showFilter ?
                    <SearchFilter 
                        show={this.state.showFilter}
                        bizTypeData={this.state.bizTypeData} 
                        selectedBizType={this.state.selectedBizType} 
                        handleBizTypeChange={this.handleBizTypeChange}
                        categoryData={this.state.categoryData}
                        selectedCategory={this.state.selectedCategory}
                        handleCategoryChange={this.handleCategoryChange}
                        closeSearchFilter={this.closeSearchFilter}
                    />
                : ""}

                {/* <Link to={`/mmdp/search?itemName=${this.state.searchName}&bizTypeId=${this.state.selectedBizType.value}&siteCategoryId=${this.state.selectedCategory.value}`}><button className="btn btn-primary" type="submit">Search</button></Link> */}
                           
            
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        businessTypes: state.businessTypes,
        categorys: state.preDefinedCategories
    }
}

export default connect(
    mapStateToProps, actions
)(AboutBanner)