import React from 'react';

import './styles.css';
import AboutBanner from '../../widgets/AboutBanner';
import HomeWidget from '../../widgets/HomeWidget';
import MetaForShare from '../../widgets/MetaForShare';
// import NewProducts from '../../widgets/NewProducts'
// import FeaturedProducts from '../../widgets/FeaturedProducts'

class Home extends React.Component {
  render() {
    return (
      <div className="home_container" style={{ marginBottom: '80px' }}>
        <MetaForShare
          title="Myanmar Digital Platform"
          url="http://localhost:3000/"
          img="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
          desc="Some Description here!"
          siteName="Glories"
        />

        <AboutBanner props={this.props} />
        <div className="product_list sep_footer" style={{ marginTop: '30px' }}>
          <HomeWidget />
        </div>
      </div>
    );
  }
}

export default Home;
