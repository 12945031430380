import React from 'react'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './styles.css'
import * as actions from '../../actions'
import noImage from '../../assets/img/no-image.jpg'

class MiniCart extends React.Component {

    constructor() {
        super()
        this.state = {
            cartData: [],
            subTotal: 0
        }
    }

    removeFromCart = (id) => {
        let cartData = []
        let localData = localStorage.getItem("cart_item")
        let sub = 0
        if(localData) {
            cartData = JSON.parse(localData)
            cartData = cartData.filter(cart => cart.item_id !== id)
            for(let cart of cartData) {
                sub += cart.qty*parseFloat(cart.item_price)
            }
        }
        this.setState({
            cartData,
            subTotal: sub
        })
        localStorage.setItem("cart_item", JSON.stringify(cartData))
        toast.success("Item Removed from the Cart");
        this.props.getShoppingCartCount()
    }

    componentDidMount() {
        let cartData = JSON.parse(localStorage.getItem(('cart_item')))

        if(!(cartData && cartData.length > 0)) 
            cartData = []

        this.setState({
            cartData: cartData
        })
    }

    componentWillReceiveProps(nextProps) {
        let cartData = JSON.parse(localStorage.getItem(('cart_item')))
        let sub = 0
        if(cartData !== this.state.cartData) {
            if(!(cartData && cartData.length > 0)) 
                cartData = []

            for(let cart of cartData) {
                sub += cart.qty*parseFloat(cart.item_price)
            }
            this.setState({
                cartData: cartData,
                subTotal: sub
            })
            
        }
    }

    render() {
        return(
            <React.Fragment>
                
                <div className={`site-header-cart-slide ${this.props.activeCart}`}>
                    <div className="cart-slide-heading">
                        <span className="cart-slide-title">
                            Shopping Cart
                        </span>
                        <span className="close-cart-slide" onClick={this.props.closeMiniCart}>close</span>
                    </div>

                    <div className="shopping_cart_slide">
                        {!(this.state.cartData && this.state.cartData.length > 0) ? 
                        <div className="shopping_cart_content">
                            <p className="cart_empty">No Products in the cart.</p>
                        </div>
                        :
                            <div className="mini_cart_scroll">
                                <ul className="mini_cart_list">
                                    {this.state.cartData.map(cart => (
                                        <li className="mini_cart_item" key={`${cart.item_id}_${cart.photo}_${Math.random()}`}>
                                            {/* <span className="remove remove_from_cart_button" onClick={e => this.removeFromCart(cart.item_id)}>
                                                <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                                            </span> */}
                                            <Link to="/productDetail" onClick={this.props.closeMiniCart}>
                                                <div className="mini_item_container">
                                                    <span className="img">
                                                        {
                                                            cart.photo && JSON.parse(cart.photo).length > 0 ?
                                                                <img src={JSON.parse(cart.photo)[0]} alt="prodct" />
                                                            :
                                                                <img src={noImage} alt="prodct" />
                                                        }
                                                    </span>
                                                    <span className="product_name">
                                                        <span className="tmp">
                                                            {cart.item_name}
                                                        </span>
                                                        <span className="tmp tmp_price">
                                                            {cart.qty} x {cart.item_price}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>  
                        }                 
                    </div>
                    
                    {(this.state.cartData && this.state.cartData.length > 0) ? 
                            <div>
                                <div className="mini_cart_total">
                                    <strong>Subtotal: </strong>
                                    <span className="subtotal_amount">
                                        {this.state.subTotal}
                                    </span>
                                </div>
    
                                <div className="mini_cart_buttons">
                                    <Link to="/mmdp/shoppingcart" onClick={this.props.closeMiniCart} className="button view_card">
                                        View Cart
                                    </Link>
                                    <a href="/mmdp" className="button">
                                        Checkout
                                    </a>
                                </div> 
                            </div>
                    : "" }
                </div>
                

                <div className="cart-slide-overlay" onClick={this.props.closeMiniCart}></div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        shoppingCartCount: state.shoppingCartCount
    }
}

export default connect(
    mapStateToProps, actions
)(MiniCart)