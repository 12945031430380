import React from 'react'

import './styles.css'
import { editCustomerInfo } from '../../api'
import { toast } from 'react-toastify'

class EditModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false,
            name: "",
            phone: "",
            address: "",
            email: "",
            addrName: "Home",
            namet: "",
            phonet: "",
            addresst: "",
            emailt: "",
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.name ? this.props.name : "",
            phone: this.props.phone ? this.props.phone : "",
            address: this.props.address ? this.props.address : "",
            email: this.props.email ? this.props.email : "",
            show: this.props.show,
            namet: this.props.name ? this.props.name : "",
            phonet: this.props.phone ? this.props.phone : "",
            addresst: this.props.address ? this.props.address : "",
            emailt: this.props.email ? this.props.email : "",
        })
    }

    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
        if(this.props.notInitial && (key === 'name' || key === 'email' || key === 'phone')) {
            this.props.handleInput(key, e)
        }
    }

    updateInfo = async () => {
        if(this.props.token) 
            localStorage.setItem('app_token', this.props.token)
        let editData = await editCustomerInfo(this.state.name, this.state.email, this.state.phone, this.state.addrName, this.state.address)
        if(editData.code === '200') {
            toast.success("Edit profile success")
            this.setState({
                show: false
            })
            this.props.editCustomerDetail()
        } else {
            toast.error(editData.message)
        }
    }

    closeModal = () => {
        this.props.handleInput("name", {target: {value: this.state.namet}})
        this.props.handleInput("phone", {target: {value: this.state.phonet}})
        this.props.handleInput("address", {target: {value: this.state.addresst}})
        this.props.handleInput("email", {target: {value: this.state.emailt}})
        this.props.editCustomerDetail()
    }

    render() {
        return(
            <div className="backdrop_edit" style={{ display: this.state.show ? "block" : "none" }}>
                <div className="edit_modal">
                    <div className="group">      
                        <input 
                            type="text" 
                            required 
                            value={this.state.name} 
                            onChange={ e=> this.handleInput("name", e)}
                            onInput={e => {
                                e.target.value = e.target.value.slice(0, 200)
                            }}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Name</label>
                    </div>

                    <div className="group">      
                        <input 
                            type="text" 
                            // disabled={this.props.accountType === 'facebook' || (!this.props.notInitial && this.props.email && this.props.email.length > 0)} 
                            required 
                            value={this.state.email} 
                            onChange={ e=> this.handleInput("email", e)}
                            onInput={e => {
                                e.target.value = e.target.value.slice(0, 100)
                            }}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        
                        {/* {this.props.accountType === 'facebook' || (!this.props.notInitial && this.props.email && this.props.email.length > 0) ? */}
                            <label style={{
                                top: "-14px",
                                fontSize: "12px",
                                color: "#2196F3"
                            }}>Email</label>
                            {/* : <label>Email</label> */}
                        {/* } */}
                    </div>
                    
                    <div className="group">
                        <input 
                            type="text" 
                            disabled={this.props.accountType === 'phone' || (!this.props.notInitial && this.props.phone.length > 0) } 
                            required 
                            value={this.state.phone ? this.state.phone : ""} 
                            onChange={ e => this.handleInput("phone", e)}
                            onInput={e => {
                                e.target.value = e.target.value.slice(0, 30)
                            }}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        {this.props.disablePhone || this.props.accountType === 'phone' || (!this.props.notInitial && this.props.phone.length > 0) ?
                            <label style={{
                                top: "-14px",
                                fontSize: "12px",
                                color: "#2196F3"
                            }}>Phone Number</label>
                            : <label>Phone Number</label>
                        }
                    </div>
                    {console.log(this.state.email)}
                    {this.props.notInitial ? "" :
                        <div>
                            <div className="group">      
                                <input 
                                    type="text" 
                                    required 
                                    value={this.state.addrName} 
                                    onChange={ e=> this.handleInput("addrName", e)}
                                    onInput={e => {
                                        e.target.value = e.target.value.slice(0, 20)
                                    }}
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Address Label</label>
                            </div>
                            
                            <div className="group">      
                                <textarea 
                                    id="w3review" 
                                    name="w3review" 
                                    value={this.state.address} 
                                    onChange={ e=> this.handleInput("address", e)}
                                    onInput={e => {
                                        e.target.value = e.target.value.slice(0, 200)
                                    }}
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Address</label>
                            </div>
                        </div>
                    }

                    <div className="btn-box">
                        <button 
                            onClick={this.updateInfo} 
                            className="btn btn-primary" 
                            type="submit"
                            disabled={
                                this.state.name && this.state.name.length > 0 && 
                                this.state.email && this.state.email.length > 0 && 
                                this.state.phone && this.state.phone.length > 0 && 
                                this.state.addrName && this.state.addrName.length > 0 && 
                                this.state.address && this.state.address.length > 0 ? false : 
                                this.props.notInitial && this.state.name && this.state.name.length > 0 && 
                                this.state.email && this.state.email.length > 0 && 
                                this.state.phone && this.state.phone.length > 0  ? false : true
                            }
                        >Update Info</button>
                        {this.props.notInitial ?
                            <button onClick={this.closeModal} className="btn btn-secondary" type="submit">Cancel</button>
                        : "" }
                    </div>
                </div>
            </div>
        )
    }
}

export default EditModal