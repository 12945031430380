import React from 'react';
import ShopProfile from '../../widgets/ShopProfile';
import { connect } from 'react-redux';

import './styles.css';
import * as actions from '../../actions';
import { getShopByBizType, searchShop } from '../../api';
import Loader from '../../widgets/Loader';
import { Link } from 'react-router-dom';
import MetaForShare from '../../widgets/MetaForShare';

class ShopList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessList: [],
      shopList: [],
      notFound: false,
    };
    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    let shopList = await getShopByBizType(this.props.match.params.bizTypeId);
    if (shopList.data) {
      if (shopList.data.length > 0)
        this.setState({
          shopList: shopList.data,
          notFound: false,
        });
      else
        this.setState({
          notFound: true,
        });
    } else {
      this.setState({
        notFound: true,
      });
    }
  }

  searchItems = async (e) => {
    if (e.key === 'Enter') {
      let shopList = await searchShop(
        this.searchInput.current.value,
        this.props.match.params.bizTypeId
      );
      if (shopList.data.length > 0)
        this.setState({
          shopList: shopList.data,
          notFound: false,
        });
      else
        this.setState({
          notFound: true,
        });
    }
  };

  searchItemsClick = async (e) => {
    let shopList = await searchShop(
      this.searchInput.current.value,
      this.props.match.params.bizTypeId
    );
    if (shopList.data) {
      if (shopList.data.length > 0)
        this.setState({
          shopList: shopList.data,
          notFound: false,
        });
      else
        this.setState({
          notFound: true,
        });
    } else {
      this.setState({
        notFound: true,
      });
    }
  };

  render() {
    return (
      <div className="bottom-space">
        <MetaForShare
          title="MMDP - Shop List"
          url={`http://localhost:3000/${this.props.match.url}`}
          img="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
          desc="Shop List of the Glories"
          siteName="Glories"
        />
        <div className="app_bar ms_row border_bottom">
          <div className="col_2">
            <button>
              <Link to="/mmdp">
                <i className="fa fa-chevron-left"></i>
              </Link>
            </button>
          </div>
          <div className="search_section col_10">
            <input
              ref={this.searchInput}
              type="text"
              placeholder="Search"
              onKeyPress={this.searchItems}
            />
            <div className="search-icon">
              <i
                onClick={this.searchItemsClick}
                className="fa fa-search"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>

        <div style={{ height: '40px', width: '100%' }}></div>

        {!this.state.notFound ? (
          this.state.shopList && this.state.shopList.length > 0 ? (
            <div
              className="ms_row"
              style={{
                padding: '10px',
              }}
            >
              {this.state.shopList && this.state.shopList.length > 0
                ? this.state.shopList.map((shop) => (
                    <div
                      className="col-6 col-md-3 col-lg-3"
                      key={`${shop.business_id}${shop.shop_name}`}
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      <ShopProfile
                        bizTypeId={this.props.match.params.bizTypeId}
                        shop={shop}
                      />
                    </div>
                  ))
                : ''}
            </div>
          ) : (
            <Loader />
          )
        ) : (
          <div>
            <div className="not-found">
              <p className="not-found-letter">Empty here</p>
              {/* <button className="btn btn-solid"><Link to="/allBizType">Go Back</Link></button> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(ShopList);
