import React from 'react'
import { Helmet } from 'react-helmet';

const MetaForShare = (props) => {
    return(
        <Helmet>
            <title>{props.title}</title>

            <meta property="og:type" content="website" />
            <meta property="og:url" content={props.url} />
            <meta property="og:site_name" content={props.siteName} />

            <meta name="twitter:card" content={props.title} />
            <meta name="twitter:domain" content={props.url} />
            <meta name="twitter:title" property="og:title" itemProp="name" content={props.title} />
            <meta name="twitter:description" property="og:description" itemProp="description" content={props.desc} />

            <meta name="description" content={props.desc} />
            <meta property="og:title" content={props.title} />
            <meta property="og:image" content={props.img} />
        </Helmet>
    )
}

export default MetaForShare