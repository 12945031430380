import React from 'react'
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { getShopByCoordinate } from '../../api'
import { Link } from 'react-router-dom'
import './styles.css'

// import axios from 'axios'

class Map extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lat: "",
            lng: "",
            selectedShop: "",
            shops: [],
            center: ""
        }
        this.refMap = React.createRef();
    }

    setSelectedShop = (shop) => {
        console.log(shop)
        this.setState({
            selectedShop: shop
        })
    }

    setDefaultLocation = () => {
        this.setState({
            lat: 16.8409,
            lng: 96.1735,
        })
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(this.showPosition, this.positionError)
        } else {
            alert("Not Available");
            this.setDefaultLocation()
        }
    }

    getLocation = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition, this.positionError);
        } else {
            alert('Geolocation is not supported by this device')
            this.setDefaultLocation()
        }
    }

    positionError = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
              alert("User denied the request for Geolocation.")
              this.setDefaultLocation()
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Location information is unavailable.")
              this.setDefaultLocation()
              break;
            case error.TIMEOUT:
              alert("The request to get user location timed out.")
              this.setDefaultLocation()
              break;
            case error.UNKNOWN_ERROR:
              alert("An unknown error occurred.")
              this.setDefaultLocation()
              break;
            default:
                break;
          }
    }
    
    showPosition = async (position) => {
        let shopByCood = await getShopByCoordinate(parseFloat(position.coords.latitude), parseFloat(position.coords.longitude))
        this.setState({
            shops: shopByCood.data
        })
        this.setState({
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude)
        }) 
    }

    handleMoveMap = () => {
        let mapCenter = this.refMap.current.getCenter()
        this.setState({
            center: mapCenter
        })
    }

    onMapMoveStop = async () => {
        let shopByCood = await getShopByCoordinate(parseFloat(this.state.center.lat()), parseFloat(this.state.center.lng()))
        this.setState({
            shops: shopByCood.data
        })

    }

    render() {
        return (
            this.state.lat && this.state.lng ?
                <GoogleMap 
                    ref={this.refMap}
                    defaultZoom={15} 
                    defaultCenter={{ lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng) }}
                    clickableIcons={false}
                    onBoundsChanged={this.handleMoveMap}
                    onDragEnd={this.onMapMoveStop}
                    onZoomChanged={this.onMapMoveStop}
                >
                    <Marker position={this.state.center} />
                    {this.state.shops && this.state.shops.length > 0 && this.state.shops.map(shop => (
                        <Marker 
                            key={shop.lat*Math.random()} 
                            position={{ 
                                lat: parseFloat(shop.lat),
                                lng: parseFloat(shop.lon)
                            }}
                            onClick={() => {
                                this.setSelectedShop(shop)
                            }}
                            icon={{
                                url: require('./shop.svg'),
                                scaledSize: new window.google.maps.Size(30, 30)
                            }}
                        />
                    ))}

                    {this.state.selectedShop && (
                        <InfoWindow 
                            position={{ 
                                lat: parseFloat(this.state.selectedShop.lat),
                                lng: parseFloat(this.state.selectedShop.lon)
                            }}
                            onCloseClick={() => this.setSelectedShop("")}
                        >
                            <div className="mini-shop-detail">
                                <h2>{this.state.selectedShop.shop_name}</h2>
                                {/* <p>{this.state.selectedShop.description}</p> */}
                                <p>{this.state.selectedShop.street}</p>
                                <p>{this.state.selectedShop.ward}</p>
                                <p>{this.state.selectedShop.township}</p>

                                <div>
                                    <span>{this.state.selectedShop.open_time} - {this.state.selectedShop.close_time}</span>
                                </div>

                                <h2 className="link">
                                    <Link to={`/mmdp/business/${this.state.selectedShop.business_id}/${this.state.selectedShop.shop_id}`}>
                                        View Shop
                                    </Link>
                                </h2>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            : <div>Not Avaible</div>
        )
    }
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

export default function Discover (props) {
    return (
        <div style={{ width: '100%', height: '100vh', marginBottom: "0" }}>
            <WrappedMap 
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${'AIzaSyB9kqYBL0f_j-PSci-fAa5U419kkA_KIYM'}`} 
                loadingElement={<div style={{height: '100%'}} />}
                containerElement={<div style={{height: '100%'}} />}
                mapElement={<div style={{height: '100%'}} />}
            />
        </div>
    )   
}