import React from 'react'

import './styles.css'
import MetaForShare from '../../widgets/MetaForShare'

const AboutUs = (props) => {

    return(
        <div className="container aboutus_container">
            <MetaForShare 
                title="MMDP - About Us" 
                url={`http://localhost:3000/${props.match.url}`}
                img="https://previews.123rf.com/images/val2014/val20141603/val2014160300005/54302312-shopping-cart-icon.jpg"
                desc="About Us of the Glories"
                siteName="Glories"
            />
            <div className="col-sm-12">
                <div className="section-title">
                    <h2 className="title">Get to know us better.</h2>
                    <p>
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla 
                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs