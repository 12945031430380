import React from 'react';

import './styles.css';
import { Link } from 'react-router-dom';
import NotFoundImg from '../../assets/img/no-image.jpg';

const ShopProfile = (props) => {
  const { shop } = props;

  return (
    <div className="shop_profile">
      <div className="card">
        <Link
          className="img-container"
          to={`/mmdp/business/${shop.business_id}/${shop.shop_id}`}
        >
          {shop.shop_img_url && JSON.parse(shop.shop_img_url).length > 0 ? (
            <img
              className="card-img-top"
              src={JSON.parse(shop.shop_img_url)[0]}
              alt="shop prodct"
            />
          ) : (
            <img className="card-img-top" src={NotFoundImg} alt="shop prodct" />
          )}
        </Link>
        <div className="card-body t_center">
          <Link to={`/mmdp/business/${shop.business_id}/${shop.shop_id}`}>
            <h5 className="card-title shop_title t_left">{shop.shop_name}</h5>
          </Link>
          <span style={{ width: '90%' }}>
            <div className="t_left">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span
                className="fa fa-star-half-o checked"
                aria-hidden="true"
              ></span>
              <span className="fa fa-star"></span>
            </div>
          </span>
          <Link
            to={`/mmdp/shopList/shopProfile/${shop.business_id}/${shop.shop_id}`}
          >
            <span
              style={{ width: '10%', fontSize: '1.3em', cursor: 'pointer' }}
              onClick={() => console.log('click')}
            >
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShopProfile;
