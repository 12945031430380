import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { requestOtp, verifyOtp } from '../../api'
import EditModal from '../../widgets/CustomerEdit';
import logo from '../../assets/img/MMDP.png'

import './styles.css'

export default function LoginPhone (props) {
    const [show, setShow] = useState(false);
    const [phoneNo, setPhoneNo] = useState("")
    const [phoneNoError, setPhoneNoError] = useState(false)
    const [requestId, setRequestId] = useState("")
    const [code, setCode] = useState([])
    const [showEditForm, setShowEditForm] = useState(false)
    const [token, setToken] = useState("")

    function getCodeBoxElement(index) {
        return document.getElementById('codeBox' + index);
    }

    function setCodeBoxElement(index, value) {
        document.getElementById('codeBox' + index).value = value
    }

    function onKeyUpEvent(index, event) {
        const eventCode = event.which || event.keyCode;
        if (getCodeBoxElement(index).value.length === 1) {
            let tmpCode = code
            tmpCode[index - 1] = getCodeBoxElement(index).value
            setCode(tmpCode)
           if (index !== 6) {
              getCodeBoxElement(index+ 1).focus();
           } else {
              getCodeBoxElement(index).blur();
              // Submit code
              console.log(code);
              verifyOtpFun()
           }
        } else {
            var str = getCodeBoxElement(index).value; 
            var res = str.charAt(str.length-1);
            setCodeBoxElement(index, res)
        }
        if (eventCode === 8 && index !== 1) {
           getCodeBoxElement(index - 1).focus();
        }
    }
    
    function onFocusEvent(index) {
        for (let item = 1; item < index; item++) {
            const currentElement = getCodeBoxElement(item);
            if (!currentElement.value) {
                currentElement.focus();
                break;
            }
        }
    }

    async function getOtp() {
        if(phoneNo && phoneNo.length > 0) {
            let otpData = await requestOtp(phoneNo)
            if(otpData.code === '200') {
                toast.success("OTP sent successfully")
                setRequestId(otpData.data.requestId)
                setShow(true)
                setPhoneNoError(false)
            } else {
                toast.error(otpData.message)
            }
        } else {
            setPhoneNoError(true)
        }
    }
    
    async function verifyOtpFun() {
        if(code.length === 6) {
            let tmpCode = ""
            for(let c of code) {
                tmpCode += c
            }
            let verify = await verifyOtp(requestId, tmpCode, phoneNo)
            console.log(verify)
            if(verify.code === '200') {
                localStorage.setItem('customerId', verify.data.customerId)
                if(verify.data.exist === 0) {
                    setToken(verify.data.token)
                    setShowEditForm(true)
                } else {
                    localStorage.setItem('app_token', verify.data.token)
                    props.history.push("/mmdp/profile")
                }
            } else {
                toast.error(verify.message)
            }
        }
    }

    function editCustomerDetail() {
        props.history.push("/mmdp/profile")
    }
    
    return(
        <div className="ph_login">
            <img src={logo} alt="profile" />
            <h2>Sign Up</h2>
            <p>It's easier to sign up now</p>

            <div>
                <InputGroup>
                    <Input placeholder="Enter your phone number" onKeyUp={e => setPhoneNo(e.target.value)} type="number" />
                    <InputGroupAddon addonType="append">
                        <Button disabled={!(phoneNo.length > 6)} color="success" onClick={getOtp}>GET OTP</Button>
                    </InputGroupAddon>
                </InputGroup>
                {phoneNoError ? <p style={{color: "red", fontStyle: "italic"}}>Enter phone number to get otp!</p> : ""}
                <div className={`_wapper ${show ? "show" : ""}`}>
                    <div className="otp_wapper">
                        <input id="codeBox1" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(1, event)} onFocus={e => onFocusEvent(1)} />
                        <input id="codeBox2" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(2, event)} onFocus={e => onFocusEvent(2)} />
                        <input id="codeBox3" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(3, event)} onFocus={e => onFocusEvent(3)} />
                        <input id="codeBox4" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(4, event)} onFocus={e => onFocusEvent(4)} />
                        <input id="codeBox5" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(5, event)} onFocus={e => onFocusEvent(5)} />
                        <input id="codeBox6" type="number" maxLength="1" onKeyUp={event => onKeyUpEvent(6, event)} onFocus={e => onFocusEvent(6)} />
                    </div>
                    {/* <div className="btn_wapper">
                        <Button color="success" onClick={verifyOtpFun}>Verify</Button>
                    </div> */}
                </div>
            </div>

            
            {showEditForm ?
                <EditModal 
                    disablePhone={true} 
                    token={token} 
                    editCustomerDetail={editCustomerDetail} 
                    phone={phoneNo} 
                    show={showEditForm} />
            :""}
        </div>
    )
}