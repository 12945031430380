import * as TYPES from './types'


import { 
    getCategoryList, 
    getItemList4EndUser, 
    getPredefinedCategories,
    getItemList4App,
    searchItems,
    getBusinessTypes,
    getItemDetail,
    findItemByProps,
    addCustomerProfileImg
    // loginFacebook
} from '../api'

export const getCategoryListAction = (businessId) => async dispatch => {
    try {
        // Store business to db here
        let categoryList = await getCategoryList(businessId)
        dispatch({ type: TYPES.GET_CATEGORY_FOR_BUSINESS, payload: categoryList })
    } catch (error) {
        console.error('Category List Error!')
    }
}

export const getItemListAction = (shopId, businessId, categoryId, siteCategoryId, itemName, index, length) => async dispatch => {
    try {
        // let itemList = await getItemList4Business(shopId, businessId);
        let itemList = await getItemList4EndUser(shopId, businessId, categoryId, siteCategoryId, itemName, index, length);
        dispatch({ type: TYPES.GET_ITEM_FOR_BUSINESS, payload: itemList })
        return itemList
    } catch (error) {
        console.error(error)
    }
}

export const searchItemsAction = (keyword, bizTypeId, siteCategoryId, section, index, length) => async dispatch => {
    try {
        // let itemList = await getItemList4Business(shopId, businessId);
        let itemList = await searchItems(keyword, bizTypeId, siteCategoryId, section, index, length);
        dispatch({ type: TYPES.SEARCH_ITEMS, payload: itemList })
        return itemList
    } catch (error) {
        console.error(error)
    }
}

export const getItemListForSiteCategory = (params) => async dispatch => {
    try {
        let itemList = await getItemList4App(params)
        dispatch({ type: TYPES.GET_ITEM_LIST_FOR_SITE_CATEGORY, payload: itemList })
    } catch (error) {   
        console.error(error)
    }
}

export const getPreCategories = () => async dispatch => {
    try {
        let preCategoriesList = await getPredefinedCategories();
        dispatch({ type: TYPES.GET_PRE_DEFINED_CATEGORIES, payload: preCategoriesList })
    } catch (error) {
        console.error(error)
    }
}


export const getBusinessTypesForApp = () => async dispatch => {
    try {
        let businessType = await getBusinessTypes();
        dispatch({ type: TYPES.GET_BUSINESS_TYPE, payload: businessType })
    } catch (error) {
        console.error(error)
    }
}

export const getWishListCount = () => async dispatch => {
    let wishList = localStorage.getItem('wishlist_item')
    let wishListCount = 0
    if(wishList) {
        wishList = JSON.parse(wishList)
        wishListCount = wishList.length
    }
    dispatch({type: TYPES.WISHLIST_COUNT, payload: {data: wishList, count: wishListCount}})
}

export const getShoppingCartCount = () => async dispatch => {
    let sCart = localStorage.getItem('cart_item')
    let sCartCount = 0
    if(sCart) {
        sCart = JSON.parse(sCart)
        sCartCount = sCart.length
    }
    dispatch({type: TYPES.SHOPPING_CART_COUNT, payload: {data: sCart, count: sCartCount}})
}

export const setSiteCategoryToStore = () => async dispatch => {

}

export const setBusinessTypesToStore = () => async dispatch => {

}


export const getItemDetailAction = (itemId, businessId, shopId, parentId = "") => async dispatch => {
    try {
        let itemDetails = await getItemDetail(itemId, businessId, shopId, parentId)
        dispatch({type: TYPES.GET_ITEM_VARIANT, payload: itemDetails})
        return itemDetails.data
    } catch (e) {
        console.error(e)
        dispatch({type: TYPES.GET_ITEM_VARIANT, payload: []})
    }
}


export const findItemByPropsAction = (pid, itemId, pCount, parentId, shopId, businessId) => async dispatch => {
    try {
        let itemDetails = await findItemByProps(pid, itemId, pCount, parentId, shopId, businessId)
        dispatch({type: TYPES.FIND_ITEM_BY_PROPS, payload: itemDetails})
        return itemDetails.data
    } catch (e) {
        console.error(e)
        dispatch({type: TYPES.FIND_ITEM_BY_PROPS, payload: []})
    }
}

export const addCustomerProfileImgActions = (base64Img) => async dispatch => {
    try {
        let itemDetails = await addCustomerProfileImg(base64Img)
        dispatch({type: TYPES.EDIT_IMAGE, payload: itemDetails})
        return itemDetails.data
    } catch (e) {
        console.error(e)
        dispatch({type: TYPES.EDIT_IMAGE, payload: []})
    }
}