import React from 'react'
import FacebookLogin from 'react-facebook-login'

import './styles.css'
import EditModal from '../CustomerEdit'
import { loginFacebook } from '../../api'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import logo from '../../assets/img/MMDP.png'

export default class LoginFacebook extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            picture: '',
            email: '',
            phone: "",
            address: "",
            showEditForm: false,
            token: "",
        }
    }

    componentClicked = () => {
        console.log("Fb btn clicked")
    }

    responseFacebook = async (response) => {
        if(response.status !== "unknown" && !response.error) {
            localStorage.setItem('fb_token', response.accessToken)
            let loginStatus = await loginFacebook(response.accessToken)
            if(loginStatus.code === '200') {
                localStorage.setItem('customerId', loginStatus.data.customerId)
                if(loginStatus.data.exist === 0) {
                    this.setState({
                        showEditForm: true,
                        name: loginStatus.data.customerName,
                        email: loginStatus.data.email,
                        token: loginStatus.data.token
                    })
                } else {
                    localStorage.setItem('app_token', loginStatus.data.token)
                    this.props.editCustomerDetail()
                }
            } else {
                toast.error(loginStatus.message)
            }
        }
    }

    loginPhone = () => {
        this.props.history.push("/mmdp/loginPhone")
    }

    render() {
        return(
            <div className="fb_login">
                <img src={logo} alt="profile" />
                <h2>Sign Up</h2>
                <p>It's easier to sign up now</p>
                <FacebookLogin
                    appId="568733423762898"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.componentClicked}
                    redirectUri={`${window.location.origin}/mmdp/profile`}
                    callback={this.responseFacebook} />

                <div className="login_phone_btn_wapper">
                    <Button className="login_phone_btn" onClick={this.loginPhone}>Login With Phone</Button>
                </div>

                {this.state.showEditForm ?
                    <EditModal token={this.state.token} editCustomerDetail={this.props.editCustomerDetail} name={this.state.name} email={this.state.email} phone={this.state.phone} address={this.state.address} show={this.state.showEditForm} />
                    :""}
            </div>
        )
    }
}