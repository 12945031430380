import React from 'react'

import { getCustomerDetail } from '../../api'
import LoginFacebook from '../../widgets/Facebook'
import Loader from '../../widgets/Loader'
import ProfileWidget from '../../widgets/Profile'

class Profile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fb_token : "",
            registeredUser: false,
            customerId: "",
            customerName: "",
            customerEmail: "",
            customerProfileImg: "",
            phone: "",
            address: "",
            accountType: "",
            loading: false,
        }
    }

    async componentDidMount() {
        let token = localStorage.getItem("app_token")

        this.setState({app_token: token, loading: true})
        if(token) {
            let customerDetail = await getCustomerDetail()
            if(customerDetail.code === '200') {
                this.setState({
                    registeredUser: true,
                    customerName: customerDetail.data[0].customer_name,
                    customerEmail: customerDetail.data[0].email,
                    customerProfileImg: customerDetail.data[0].profile_pic_url,
                    address: customerDetail.data[0].address1,
                    phone: customerDetail.data[0].phone,
                    accountType: customerDetail.data[0].account_type,
                    loading: false
                })
            } else {
                this.setState({
                    registeredUser: false,
                    loading: false
                })
            }
        } else {
            this.setState({loading: false})
        }
    }

    editCustomerDetail = async () => {
        let token = localStorage.getItem("app_token")
        let customerDetail = await getCustomerDetail(token)
        if(customerDetail.code === '200') {
            this.setState({
                registeredUser: true,
                customerName: customerDetail.data[0].customer_name,
                customerEmail: customerDetail.data[0].email,
                customerProfileImg: customerDetail.data[0].profile_pic_url,
                address: customerDetail.data[0].address1,
                phone: customerDetail.data[0].phone,
                accountType: customerDetail.data[0].account_type,
            })
        } else {
            this.setState({
                registeredUser: false,
            })
        }
        this.props.history.push('/mmdp/profile')
    }

    handleLogout = () => {
        localStorage.removeItem("app_token")
        localStorage.removeItem("fb_token")
        localStorage.removeItem("customerId")
        window.location.reload(false)
    }

    render() {
        return(
            this.state.loading ? <Loader />
                :
                this.state.registeredUser ? 
                    <ProfileWidget 
                        props={this.props} 
                        logout={this.handleLogout} 
                        name={this.state.customerName} 
                        phone={this.state.phone} 
                        address={this.state.address} 
                        email={this.state.customerEmail} 
                        pic={this.state.customerProfileImg}
                        accountType={this.state.accountType}
                    />
                : <LoginFacebook history={this.props.history} editCustomerDetail={this.editCustomerDetail} />
        )
    }
}

export default Profile