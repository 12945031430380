import React from 'react'

import './styles.css'
import { Link } from 'react-router-dom'
import Loader from '../Loader'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import moreIcon from '../../assets/img/more-512.png'

class HomeWidget extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            businessTypeList: []
        }
    }
    
    componentDidMount() {
        this.props.getBusinessTypesForApp()
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.businessTypes !== nextProps.businessTypes) {
            this.setState({
                businessTypeList: nextProps.businessTypes.data
            })
        }
    }

    render() {   
        let i = 0     
        return (
            <div className="home_widget_container">
                <div className="ms_row">
                    {this.state.businessTypeList && this.state.businessTypeList.length > 0 
                        ?
                            this.state.businessTypeList.map(list => {
                                i++;
                                return i <= 7 ? 
                                <div className="ms_col col_3 t_center h_menu_item" key={list.biz_type_id}>
                                    <Link to={`/mmdp/shopList/${list.biz_type_id}`}>
                                        <i><img src={list.image_url} alt="menu" /></i>
                                        <span>{list.biz_type_name}</span>
                                    </Link>
                                </div>
                                : ""
                            })
                            : 
                            <Loader />
                    }
                    
                    <div className="ms_col col_3 t_center h_menu_item" key="more">
                        <Link to={`/mmdp/allBizType`}>
                            <i><img src={moreIcon} alt="menu" /></i>
                            <span>More</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        businessTypes: state.businessTypes
    }
}

export default connect(
    mapStateToProps, actions
)(HomeWidget)