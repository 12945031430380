import React from 'react'

import './styles.css'
import EditModal from '../../widgets/CustomerEdit'
import { Link } from 'react-router-dom'
import defaultProfileImg from '../../assets/img/profile.png'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import ReactCrop from 'react-image-crop';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-image-crop/dist/ReactCrop.css';

class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            profileImg: "",
            phone: "",
            address: "",
            showEditForm: false,
            updateImage: "",
            uploadImgLoading: false,
            cropModal: false,
            crop: {
                width: 100,
                height: 100
            },
            src: "",
            base64: "",
            croppedImageUrl: ""
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.name,
            email: this.props.email,
            profileImg: this.props.pic,
            phone: this.props.phone,
            address: this.props.address
        })
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.image !== nextProps.image) {
            this.setState({ uploadImgLoading: false })
            if(nextProps.image.code === '200') this.setState({ profileImg: nextProps.image.data }) 
            else toast.error(nextProps.image.message)
        }
    }

    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    showEditForm = () => {
        this.setState({
            showEditForm: true
        })
    }

    closeModal = () => {
        this.setState({
            showEditForm: false
        })
    }

    fileToBase64 = async (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (e) => reject(e)
        })

    handleUpdateImage = async (e) => {
        this.openCropModal()
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({ 
                src: reader.result,
            })
          );
          reader.readAsDataURL(e.target.files[0]);
          e.target.value = null
        }

        // this.setState({uploadImgLoading: true})
        // const file = e.target.files[0]
        // e.target.value = null
        // if (file) {
        //     let base64 = await this.fileToBase64(file)
        //     this.props.addCustomerProfileImgActions(base64)
        // }
    }

    openCropModal = () => {
        this.setState({
            cropModal: true,
        })
    }

    closeCropModal = () => {
        this.setState({
            cropModal: false,
            uploadImgLoading: true,
            crop: {
                width: 100,
                height: 100
            }
        })
        this.props.addCustomerProfileImgActions(this.state.base64)
    }

    onImageLoaded = image => {
        this.imageRef = image;
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ 
                croppedImageUrl,
            });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return;
                }
                this.setState({
                    base64: canvas.toDataURL()
                })
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }
    
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    render() {
        return (
            <div>
                <div className="profile_container">

                </div>

                <div className="wrapper">
                    <div className="profile_detail">
                        <div className="img_container">
                            <div class="avatar-preview">
                                {this.state.uploadImgLoading && <Loader color="purple" imgUpload={true}/>}
                                <img 
                                    src={this.state.profileImg ? this.state.profileImg : defaultProfileImg} 
                                    alt={this.state.name} 
                                />
                                <div class="avatar-edit">
                                    <input 
                                        type='file' 
                                        id="imageUpload" 
                                        accept=".png, .jpg, .jpeg" 
                                        onChange={this.handleUpdateImage}
                                        disabled={this.state.uploadImgLoading}
                                    />
                                    <label for="imageUpload"></label>
                                </div>
                            </div>
                        </div>
                        <p>{this.state.name}</p>
                        <p>{this.state.email}</p>
                        <div className="white_space">
                            <button onClick={this.showEditForm}>
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div className="settings bottom-space">
                        <Link to="/mmdp/orders" className="list">
                            <button>
                                <i className="fa fa-list-alt" aria-hidden="true"></i>
                            </button>
                            <span>My Orders</span>
                        </Link>
                        <Link to="/mmdp/collections" className="list">
                            <button>
                                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                            </button>
                            <span>My Collection</span>
                        </Link>
                        <Link to="/mmdp/addresses" className="list">
                            <button>
                                <i className="fa fa-address-book-o" aria-hidden="true"></i>
                            </button>
                            <span>My Addresses</span>
                        </Link>
                        <Link to="/mmdp/help" className="list">
                            <button>
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                            </button>
                            <span>Help Center</span>
                        </Link>
                        <div onClick={this.props.logout} className="list">
                            <button>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>
                            </button>
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
                {this.state.showEditForm ?
                    <EditModal 
                        accountType={this.props.accountType} 
                        notInitial={true} 
                        handleInput={this.handleInput}
                        editCustomerDetail={this.closeModal} 
                        name={this.state.name} email={this.state.email} 
                        phone={this.state.phone} 
                        address={this.state.address} 
                        show={this.state.showEditForm} />
                    : ""}


                
                {/* Crop Image */}
                {/* <Modal
                    className={classes.modal}
                    open={this.state.cropModal}
                    // onClose={this.closeCropModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                > */}
                    
                <Modal isOpen={this.state.cropModal} toggle={this.closeCropModal}>
                    <div className="crop-modal">
                        {this.state.cropModal && (
                            <ReactCrop
                                src={this.state.src}
                                crop={this.state.crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        )}
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            style={{
                                width: "100%",
                                backgroundColor: "#3f51b5",
                                color: "white"
                            }}
                            onClick={this.closeCropModal}
                        >
                            Done
                        </Button>
                    </div>
                         
                        
                   
                </Modal>

            </div>
        )
    }
}

// export default Profile


function mapStateToProps(state) {
    return {
        image: state.image
    }
}

export default connect(
    mapStateToProps, actions
)(Profile)
