import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// import axios from 'axios'

import './styles.css';
import Product from '../../widgets/ProductMiniDetail';
// import SideFilter from '../../widgets/SideFilter'
// import MobileSideFilter from '../../widgets/MobileSideFilter'
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Loader from '../../widgets/Loader';
import MetaForShare from '../../widgets/MetaForShare';

class MenuPage extends React.Component {
  constructor() {
    super();
    this.state = {
      limit: 8,
      hasMoreProduct: true,

      filterActive: '',
      businessCategoryList: [],
      businessId: '',
      // businessName: "",

      businessItemList: [],
      layoutStyle: '',
      shopId: '',
      index: 0,
      length: 10,
      totalRows: 0,

      items: Array.from({ length: 20 }),
      hasMore: true,
      searchName: '',
      notFound: false,
      notFoundMsg: 'No Data Found!',
      stopScroll: false,
    };
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let layoutStyle = localStorage.getItem('layoutStyle');
    this.setState({
      businessId: params.businessId,
      // businessName: params.businessName,
      layoutStyle: layoutStyle,
      shopId: params.shopId,
    });
    // this.props.getCategoryListAction(params.businessId)
    this.props.getItemListAction(
      params.shopId,
      params.businessId,
      '',
      '',
      '',
      this.state.index,
      this.state.length
    );
  }

  componentWillReceiveProps(nextProps) {
    let { params } = this.props.match;
    if (this.state.businessId !== params.businessId) {
      this.setState({
        businessId: params.businessId,
        // businessName: encodeURI(params.businessName)
      });
      // this.props.getCategoryListAction(params.businessId)
      this.props.getItemListAction(
        this.state.shopId,
        params.businessId,
        '',
        '',
        this.state.searchName,
        this.state.index,
        this.state.length
      );
    }

    // if(this.props.businessCategory !== nextProps.businessCategory) {
    //     this.setState({
    //         businessCategoryList: nextProps.businessCategory.data
    //     })
    // }

    if (this.props.businessItem !== nextProps.businessItem) {
      // if(nextProps.businessItem.data && nextProps.businessItem.data.items)
      //     this.setState({
      //         businessItemList: nextProps.businessItem.data.items
      //     })
      // else {
      //     this.setState({
      //         businessItemList: []
      //     })
      // }
      if (nextProps.businessItem.code === '200') {
        if (nextProps.businessItem.data) {
          if (nextProps.businessItem.data.length > 0) {
            this.setState({
              businessItemList: this.state.businessItemList.concat(
                Array.from(nextProps.businessItem.data)
              ),
              totalRows: nextProps.businessItem.totalRow,
              notFound: false,
              stopScroll: false,
            });
          } else {
            if (this.state.businessItemList.length > 0) {
              this.setState({
                stopScroll: true,
              });
            } else {
              this.setState({
                businessItemList: [],
                notFound: true,
                stopScroll: false,
              });
            }
          }
        } else {
          this.setState({
            businessItemList: [],
            notFound: true,
            notFoundMsg: nextProps.businessItem.message,
            stopScroll: false,
          });
        }
      } else {
        if (this.state.businessItemList.length > 0) {
          this.setState({
            stopScroll: true,
          });
        } else {
          this.setState({
            businessItemList: [],
            notFound: true,
            notFoundMsg: nextProps.businessItem.message,
            stopScroll: false,
          });
        }
      }
    }
  }

  fetchMoreData = async () => {
    if (this.state.stopScroll) {
      this.setState({
        hasMore: false,
      });
      return;
    }
    let ind = this.state.index + this.state.length;
    this.props.getItemListAction(
      this.state.shopId,
      this.state.businessId,
      '',
      '',
      this.state.searchName,
      ind,
      this.state.length
    );
    this.setState({
      // businessItemList: this.state.businessItemList.concat(Array.from(items.data)),
      index: ind,
    });
  };

  // showFilter = () => {
  //     this.setState({
  //         filterActive: 'active'
  //     })
  // }

  // closeFilter = () => {
  //     this.setState({
  //         filterActive: ''
  //     })
  // }

  changeLayout = (layoutStyle) => {
    localStorage.setItem('layoutStyle', layoutStyle);
    this.setState({
      layoutStyle,
    });
  };

  searchItems = async (e) => {
    if (e.key === 'Enter') {
      this.setState({
        businessItemList: [],
        hasMore: false,
      });
      this.props.getItemListAction(
        this.state.shopId,
        this.state.businessId,
        '',
        '',
        e.target.value,
        0,
        this.state.length
      );
      this.setState({
        index: 0,
        searchName: e.target.value,
      });
    }
  };

  searchItemsClick = async (e) => {
    this.setState({
      businessItemList: [],
      hasMore: false,
    });
    this.props.getItemListAction(
      this.state.shopId,
      this.state.businessId,
      '',
      '',
      this.searchInput.current.value,
      0,
      this.state.length
    );
    this.setState({
      index: 0,
      searchName: this.searchInput.current.value,
    });
  };

  render() {
    return (
      <div className="bottom-space">
        <MetaForShare
          title="MMDP - Item List"
          url={`http://localhost:3000/${this.props.match.url}`}
          img="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
          desc="Item List"
          siteName="Glories"
        />

        <div className="app_bar ms_row border_bottom">
          <div className="col_2">
            <button onClick={() => this.props.history.goBack()}>
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="search_section col_10">
            <input
              ref={this.searchInput}
              type="text"
              placeholder="Search"
              onKeyPress={this.searchItems}
            />
            <div className="search-icon">
              <i
                onClick={this.searchItemsClick}
                className="fa fa-search"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          {/* <div className="group col_2">
                        <i className="fa fa-bars" aria-hidden="true" onClick={e => this.changeLayout("list")}></i>
                        <i className="fa fa-th" aria-hidden="true" onClick={e => this.changeLayout("grid")}></i>
                    </div> */}
        </div>

        <div style={{ height: '40px', width: '100%' }}></div>

        {this.state.businessItemList &&
        this.state.businessItemList.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.businessItemList.length}
            next={this.fetchMoreData}
            hasMore={true}
            // loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>No more items</b>
              </p>
            }
          >
            <div
              className="ms_row"
              style={{
                padding: '10px',
              }}
            >
              {this.state.businessItemList.map((product, index) => (
                <div
                  className="col-6 col-md-3 col-lg-3"
                  style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                  <Product
                    businessId={this.state.businessId}
                    shopId={this.state.shopId}
                    layout={this.state.layoutStyle}
                    product={product}
                    imgs={
                      product.pic_or_color
                        ? JSON.parse(product.pic_or_color)
                        : ''
                    }
                    key={index}
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div>
            {this.state.notFound ? (
              <div className="not-found">
                <p className="not-found-letter">{this.state.notFoundMsg}</p>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        )}

        {/* <MobileSideFilter businessCategoryList={this.state.businessCategoryList}  filterActive={this.state.filterActive} closeFilter={this.closeFilter}/> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    businessCategory: state.businessCategory,
    businessItem: state.businessItem,
  };
}
export default connect(mapStateToProps, actions)(MenuPage);
