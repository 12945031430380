import React from 'react'
import { toast } from 'react-toastify'
import { Button, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { getCustomerAddressList, addCustomerAddress, deleteCustomerAddress, setDefaultCustomerAddress } from '../../api'

import './styles.css'

class MyAddress extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tooltipOpen: false,
            addList: [],
            addrName: "",
            addrValue:  "",
            modal: false
        }
    }

    async componentDidMount() {
        let customerAddress = await getCustomerAddressList()
        
        if(customerAddress.code === '200') {
            this.setState({
                addList: customerAddress.data
            })
        } else {
            toast.error(customerAddress.message)
        }
    }

    setToolTipOpen = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    refreshData = async () => {
        let customerAddress = await getCustomerAddressList()
        
        if(customerAddress.code === '200') {
            this.setState({
                addList: customerAddress.data
            })
        } else {
            toast.error(customerAddress.message)
        }
    }

    addCustomerAddress = async () => {
        let addData = await addCustomerAddress(this.state.addrName, this.state.addrValue)
        if(addData.code === '200') {
            toast.success("Add Customer Address successful!")
            this.setState({
                modal: !this.state.modal,
                addrName: "",
                addrValue: ""
            })
            
            this.refreshData()
        } else {
            toast.error(addData.message)
        }
    }

    setModalOpen = () => {
        this.setState({
            modal: !this.state.modal,
            addrName: "",
            addrValue: ""
        })
    }

    onInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }
    
    deleteCustomerAddress = async (addrName) => {
        
        let deleteData = await deleteCustomerAddress(addrName)
        
        if(deleteData.code === '200') {
            toast.success('Delete successful!')
            this.refreshData()
        } else {
            toast.error(deleteData.message)
        }
    }

    setDefaultCustomerAddress = async (addrName) => {
        console.log(addrName)
        let defaultData = await setDefaultCustomerAddress(addrName)
        
        if(defaultData.code === '200') {
            toast.success('Set Default successful!')
            this.refreshData()
        } else {
            toast.error(defaultData.message)
        }
    }

    render() {
        
        return (
          <div className="bottom-space">
            <div className="app_bar ms_row border_bottom">
              <div className="col_2 t_left">
                <button>
                  <i
                    onClick={(e) => this.props.history.goBack()}
                    className="fa fa-chevron-left"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div className="search_section col_8">
                <h2
                  style={{
                    fontSize: '1.2em',
                  }}
                >
                  My Addresses
                </h2>
              </div>
            </div>

            <div style={{ height: '50px' }}></div>

            <div className="add-btn-wrapper">
              <button type="button" className="add-address" onClick={this.setModalOpen}>
                <span>Add New Address</span>
              </button>
            </div>

            <div className="addresses">
              <div className="address-list">
                {this.state.addList && this.state.addList.length > 0
                  ? this.state.addList.map((address, index) => (
                      <div className="address-list-item">
                        <div className="address-item-wrapper">
                          <div className="item-select">
                            {address.is_default !== 1 ? (
                                <label className="custom-radio">
                                    <span >
                                        <span className='custom-radio-circle'></span>
                                    </span>
                                <input
                                    onChange={() =>
                                    this.setDefaultCustomerAddress(
                                        address.addr_name
                                    )}
                                  checked={
                                    address.is_default === 1 ? true : false
                                  }
                                  id={'Tooltip-' + index}
                                  type="radio"
                                />
                                </label> 
                            ) : (
                              <label className="custom-radio custom-radio-checked">
                                <span className="checked">
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="false"
                                    focusable="false"
                                  >
                                    <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 64c-200.298667 0-362.666667 162.368-362.666667 362.666667s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667-162.368-362.666667-362.666667-362.666667z m0 149.333334c117.824 0 213.333333 95.509333 213.333333 213.333333s-95.509333 213.333333-213.333333 213.333333-213.333333-95.509333-213.333333-213.333333 95.509333-213.333333 213.333333-213.333333z"></path>
                                  </svg>
                                </span>
                                <input
                                  onChange={() =>
                                    this.setDefaultCustomerAddress(
                                      address.addr_name
                                    )
                                  }
                                  checked={
                                    address.is_default === 1 ? true : false
                                  }
                                  id={'Tooltip-' + index}
                                  type="radio"
                                />
                              </label>
                            )}
                          </div>

                          <div className="address-content-wrapper">
                              <div className="address-content">
                                  <div className="name">
                                    {address.addr_name}
                                  </div>
                                  <div className="address-info">
                                      {address.addr_value}
                                  </div>
                              </div>

                          </div>

                          <div className='address-control'>
                                <div className="address-control-del-btn">
                                    <span 
                                        onClick={() =>
                                        this.deleteCustomerAddress(address.addr_name)
                                        }
                                    >
                                        <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"></i>
                                    </span>
                                </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
            </div>

            <Modal isOpen={this.state.modal} toggle={this.setModalOpen} style={{top: "20%"}}>
              <ModalHeader toggle={this.setModalOpen}>Add Address</ModalHeader>
              <ModalBody>
                <div>
                  <Form>
                    <FormGroup row>
                      <Label for="addLabel" sm={2}>
                        Label
                      </Label>
                      <Col sm={8}>
                        <Input
                          onKeyUp={(e) => this.onInput('addrName', e)}
                          type="text"
                          name="label"
                          id="addLabel"
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 30);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="addVal" sm={2}>
                        Address
                      </Label>
                      <Col sm={8}>
                        <Input
                          onKeyUp={(e) => this.onInput('addrValue', e)}
                          type="textarea"
                          name="value"
                          id="addVal"
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 200);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  disabled={!this.state.addrName && !this.state.addrValue}
                  onClick={this.addCustomerAddress}
                >
                  Add
                </Button>{' '}
                <Button color="secondary" onClick={this.setModalOpen}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        );
    }
}

export default MyAddress