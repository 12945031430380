import React from 'react'

import './styles.css'
import { checkLogin, getOrderOptions, addPosSale, confirmPayment, getCustomerAddressList } from '../../api'
import LoginFacebook from '../../widgets/Facebook'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Loader from '../../widgets/Loader'
import FormGroup from 'reactstrap/lib/FormGroup'

class CheckOut extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            login: false,
            orderOptions: [],
            paymetMethods: [],
            selectedOrderOption: "take_away",
            selectedPaymentMethod: "1",
            ayaPhone: "",

            modal: false,
            addressList: [],
            getAddressError: false,
            getAddressErrorMsg: "",
            defaultName: "",
            defaultValue: "",

            addressModal: false,
            loading: false,
            showPaymentCancel: false,
            confirmCancelModal: false,
            saiSaiPay: "",
        }
    }

    async componentDidMount() {
        this.setState({loading: true})
        let login = await checkLogin()
        let orderedItems = localStorage.getItem('cart_item') ? JSON.parse(localStorage.getItem('cart_item')) : []
        if(orderedItems.length > 0) {
            this.setState({
                orderedItems: orderedItems
            })
        } else {
            this.props.history.goBack()
        }
        if(login && orderedItems.length > 0) {
            let orderOptions = await getOrderOptions()
            if(orderOptions.code === '200') {
                this.setState({
                    orderOptions: orderOptions.data.orderOptions.split(","),
                    paymetMethods: orderOptions.data.paymentMethods
                })
            } else {
                toast.error(orderOptions.message)
            }
        }
        this.setState({
            login,
            loading: false
        })

        this.refreshCustomerAddresss()
    }

    async refreshCustomerAddresss() {
        let addresses = await getCustomerAddressList()
        if(addresses.code === '200') {
            for(let addr of addresses.data) {
                if(addr.is_default === 1) {
                    this.setState({
                        defaultName: addr.addr_name,
                        defaultValue: addr.addr_value
                    })
                }
            }
            this.setState({
                addressList: addresses.data,
                getAddressError: false,
            })
        } else {
            this.setState({
                getAddressError: true,
                getAddressErrorMsg: addresses.message
            })
        }
    }

    editCustomerDetail = async () => {
        this.setState({ loading: true })
        let login = await checkLogin()
        let orderedItems = localStorage.getItem('cart_item') ? JSON.parse(localStorage.getItem('cart_item')) : []
        if (orderedItems.length > 0) {
            this.setState({
                orderedItems: orderedItems
            })
        } else {
            this.props.history.goBack()
        }
        if (login && orderedItems.length > 0) {
            let orderOptions = await getOrderOptions()
            if (orderOptions.code === '200') {
                this.setState({
                    orderOptions: orderOptions.data.orderOptions.split(","),
                    paymetMethods: orderOptions.data.paymentMethods
                })
            } else {
                toast.error(orderOptions.message)
            }
        }
        await this.refreshCustomerAddresss()
        this.setState({
            login,
            loading: false
        })
        // this.props.history.push('/mmdp/profile')
    }

    onOrderOptionChange = (e) => {
        this.setState({
            selectedOrderOption: e.target.value
        })
    }

    handleCheckout = async () => {
        let shopId = localStorage.getItem("shop_id")
        let businessId = localStorage.getItem("businessId")
        let orderedItems = localStorage.getItem('cart_item') ? JSON.parse(localStorage.getItem('cart_item')) : []
        let add = {}
        let deliAddress = ""
        if(this.state.selectedOrderOption === 'delivery' ) {
            deliAddress = this.state.defaultValue
        } else {
            deliAddress = ""
        }
        if(this.state.selectedPaymentMethod === '2' && this.state.ayaPhone.length > 0) {
            add = await addPosSale(shopId, businessId, this.state.selectedPaymentMethod, this.state.selectedOrderOption, orderedItems, deliAddress, this.state.ayaPhone)
            if(add.code === '200') {
                toast.success("Successful!")
                this.setModalOpen2(true)
                let myprops = this.props
                var timerID = setInterval(async function() {
                    let confirm = await confirmPayment(add.data.saleNo, add.data.shopId, add.data.businessId)
                    if(confirm.code === '200' && confirm.data.transactionStatus === "success") {
                        clearInterval(timerID); 
                        localStorage.removeItem('cart_item')
                        localStorage.removeItem('shop_id')
                        localStorage.removeItem('businessId')
                        myprops.getShoppingCartCount()
                        toast.success("Order Successful!")
                        myprops.history.push(`/mmdp/business/${businessId}/${shopId}`)
                    }
                }, 5 * 1000); 
                
                const timer = setTimeout(() => {
                    this.setState({
                        showPaymentCancel: true
                    })
                }, 10 * 1000);
                return () => clearTimeout(timer);
            } else {
                toast.error(add.message)
            }
        } else if(this.state.selectedPaymentMethod === '3' && this.state.saiSaiPay.length > 0) {
            add = await addPosSale(shopId, businessId, this.state.selectedPaymentMethod, this.state.selectedOrderOption, orderedItems, deliAddress, this.state.saiSaiPay)
            if(add.code === '200') {
                toast.success("Successful!")
                this.setModalOpen2(true)
                let myprops = this.props
                var timerID2 = setInterval(async function() {
                    let confirm = await confirmPayment(add.data.saleNo, add.data.shopId, add.data.businessId)
                    if(confirm.code === '200' && confirm.data.transactionStatus === "success") {
                        clearInterval(timerID2); 
                        localStorage.removeItem('cart_item')
                        localStorage.removeItem('shop_id')
                        localStorage.removeItem('businessId')
                        myprops.getShoppingCartCount()
                        toast.success("Order Successful!")
                        myprops.history.push(`/mmdp/business/${businessId}/${shopId}`)
                    }
                }, 5 * 1000); 
                
                const timer = setTimeout(() => {
                    this.setState({
                        showPaymentCancel: true
                    })
                }, 10 * 1000);
                return () => clearTimeout(timer);
            } else {
                toast.error(add.message)
            }
        } else if(this.state.selectedPaymentMethod !== '2') {
            add = await addPosSale(shopId, businessId, this.state.selectedPaymentMethod, this.state.selectedOrderOption, orderedItems, deliAddress)
            if(add.code === '200') {
                this.props.history.push(`/mmdp/business/${localStorage.getItem('businessId')}/${localStorage.getItem('shop_id')}`)
                toast.success("Order successful!")
                localStorage.removeItem('cart_item')
                localStorage.removeItem('shop_id')
                localStorage.removeItem('businessId')
                this.props.getShoppingCartCount()
            } else {
                toast.error(add.message)
            }
        }
    }

    handlePaymentMethod = (e) => {
        this.setState({
            selectedPaymentMethod: e.target.value
        })
    }

    handleAYAPhoneNumber = (e) => {
        this.setState({
            ayaPhone: e.target.value
        })
    }

    handleSauSaiPayPhoneNumber = e => {
        this.setState({
            saiSaiPay: e.target.value
        })
    }

    setAddrModalOpen = () => {
        this.setState({
            addressModal: !this.state.addressModal
        })
    }


    setModalOpen = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    setModalOpen2 = (cond) => {
        this.setState({
            modal: cond,
        })
    }

    setDefaultCustomerAddress = (name, value) => {
        this.setState({
            defaultName: name,
            defaultValue: value
        })

        this.setAddrModalOpen()
    }

    showConfirmCancelModal = () => {
        this.setState((state, props) => ({
            confirmCancelModal: !state.confirmCancelModal
        }))
    }

    cancelPayment = () => {
        this.setState({
            confirmCancelModal: false,
            modal: false,
        })
    }

    render() {
        return(
            this.state.loading ? <Loader /> :
            this.state.login ?
                <div className="checkout_container bottom-space">
                    <div className="app_bar ms_row border_bottom">
                        <div className="col_2">
                            <button><i onClick={e => this.props.history.goBack()} className="fa fa-chevron-left" aria-hidden="true"></i></button>
                        </div>
                    </div>

                    <div style={{width: "100%", height: "60px"}}></div>
                    
                    <div className="checkout_options">
                        <h3>What do you want to do?</h3>
                        {this.state.orderOptions && this.state.orderOptions.length > 0 &&
                            this.state.orderOptions.map(option => (
                                <div className="option" key={option}>
                                    <input type="radio" 
                                        id={option} 
                                        name="order-options" 
                                        value={option} 
                                        checked={this.state.selectedOrderOption === option}
                                        onChange={this.onOrderOptionChange}
                                    />
                                    <label htmlFor={option}>{option === 'take_away' ? "Take Away" : option === 'delivery' ? "Delivery" : option}</label><br></br>
                                </div>
                            ))
                        }
                    </div>

                    {this.state.selectedOrderOption === 'delivery' ?
                        <div className="delivary_address_wrapper">
                            <div className="delivary_address_wrapper_inner">
                                <span className="title">{this.state.defaultName}</span>
                                <span>{this.state.defaultValue}</span>
                            </div>
                            <span className="link" onClick={this.setAddrModalOpen}>Click to change address</span>
                        </div>
                    : "" }
                    <div className="checkout_options">
                        <h3>Choose payment method</h3>
                        {this.state.paymetMethods && this.state.paymetMethods.length > 0 &&
                            <FormGroup>
                                <Input type="select" name="select" id="payment-method" onChange={this.handlePaymentMethod}>
                                    {this.state.paymetMethods.map(method => (
                                        <option key={method.method_id} value={method.method_id} >{method.method_name} </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        }
                    </div>

                    {this.state.selectedPaymentMethod === "2" ?
                        <div className="checkout_options">
                            <Input type="number" defaultValue={this.state.ayaPhone} placeholder="Enter your AYA Pay phone number to proceed" onKeyUp={e => this.handleAYAPhoneNumber(e)}/>
                        </div>
                    :""}

                    
                    {this.state.selectedPaymentMethod === "3" ?
                        <div className="checkout_options">
                            <Input type="number" defaultValue={this.state.saiSaiPay} placeholder="Enter your Sai Sai Pay phone number to proceed" onKeyUp={e => this.handleSauSaiPayPhoneNumber(e)}/>
                        </div>
                    :""}

                    <div className="btn-box">
                        <button className="btn btn-secondary" onClick={ e => this.props.history.goBack()}>Cancel</button>
                        <button 
                            className="btn btn-primary" 
                            onClick={this.handleCheckout} 
                            disabled={this.state.selectedPaymentMethod === '1' ? false 
                                : (this.state.selectedPaymentMethod === '2' && this.state.ayaPhone.length > 0) ? false : 
                                (this.state.selectedPaymentMethod === '3' && this.state.saiSaiPay.length > 0) ? false :
                                true}
                        >
                            Proceed
                        </button>
                    </div>
                
                    <Modal isOpen={this.state.modal} toggle={this.setModalOpen} backdrop="static" keyboard={false} children={true}>
                        <ModalHeader>Please Confirm your payment</ModalHeader>
                        <ModalBody>
                            <div>
                                Waiting for payment confirmation...
                            </div>
                            {this.state.showPaymentCancel ? 
                                <div className="btn-box-2">
                                    <button
                                        onClick={this.showConfirmCancelModal}
                                        className="btn btn-danger" 
                                    >Cancel</button>
                                </div>
                            : ""}
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.confirmCancelModal} backdrop="static" keyboard={false} children={true}>
                        <ModalHeader>Are you sure you want to cancel your payment?</ModalHeader>
                        <ModalBody>
                            <div className="btn-box-2">
                                <button className="btn btn-secondary" onClick={this.showConfirmCancelModal}>No</button>
                                <button className="btn btn-danger" onClick={this.cancelPayment}>Yes</button>
                            </div>
                        </ModalBody>
                    </Modal>
                    
                
                    <Modal isOpen={this.state.addressModal} toggle={this.setAddrModalOpen} backdrop="static" keyboard={false} children={true}>
                        <ModalHeader>Choose Your address</ModalHeader>
                        <ModalBody>
                            <div className="delivary_address_wrapper">
                                {this.state.addressList && this.state.addressList.length > 0 ?
                                    this.state.addressList.map(address => (
                                        <div className="delivary_address_wrapper_inner address_hover" style={{marginBottom: "10px"}} onClick={e => this.setDefaultCustomerAddress(address.addr_name, address.addr_value)}>
                                            <span className="title">{address.addr_name}</span>
                                            <span>{address.addr_value}</span>
                                        </div>
                                    ))
                                : ""}
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            : 
            <LoginFacebook editCustomerDetail={this.editCustomerDetail} history={this.props.history} />
        )
    }
}

function mapStateToProps(state) {
    return {
        preDefinedCategories: state.preDefinedCategories,
        wishlistCount: state.wishlistCount,
        shoppingCartCount: state.shoppingCartCount
    }
}

export default connect(
    mapStateToProps, actions
)(CheckOut)