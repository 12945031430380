import React from 'react';
import './App.css';
import './widgets/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Components/Home';
import { Switch, Route, Redirect } from 'react-router-dom';
import WishList from './Components/WishList';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import MenuPage from './Components/Shop/Menu';
import ItemDetail from './Components/ProductDetail';
import Contact from './Components/Contact';
import AboutUs from './Components/AboutUs';
import CategoryDataList from './Components/CategoryDataList';
import ShopList from './Components/ShopList';
import ComingSoon from './widgets/ComingSoon';
import ShoppingCart from './Components/ShoppingCart';
import Search from './Components/Search';
import AllBizType from './Components/AllBizType';
import Profile from './Components/Profile';
import OrderHistory from './Components/CustomerOrders';
import CheckOut from './Components/Checkout';
import OrderDetail from './Components/OrderDetail';
import Discover from './Components/Discover';
import ShopSearch from './Components/ShopSearch';
import ItemSearch from './Components/ItemSearch';
import LoginPhone from './Components/PhoneLogin';
import MyAddress from './Components/MyAddress';
import ShopDetail from './Components/Shop/ShopDetail';
import Collections from './Components/Collections';
import HelpCenter from './Components/Help';
import ShopListTest from './Components/TestingComponents/ShopList';
import ItemListDesign from './Components/TestingComponents/ItemList';

function App() {
  return (
    <div className="App" style={{ marginTop: '60px' }}>
      <header className="App-header">
        <Switch>

          <Route exact path={`/mmdp`} component={Home} />
          <Route
            exact
            path={`/mmdp/shopList/:bizTypeId`}
            component={ShopList}
          />
          <Route
            exact
            path={`/mmdp/shopList/shopProfile/:businessId/:shopId`}
            component={ShopDetail}
          />
          <Route
            exact
            path={`/mmdp/business/:businessId/:shopId`}
            component={MenuPage}
          />
          <Route
            exact
            path={`/mmdp/productDetail/:businessId/:shopId/:parentId/:itemId`}
            component={ItemDetail}
          />
          <Route exact path={`/mmdp/allBizType`} component={AllBizType} />
          <Route exact path={`/mmdp/search`} component={Search} />
          <Route exact path={`/mmdp/itemSearch`} component={ItemSearch} />
          <Route exact path={`/mmdp/shopSearch`} component={ShopSearch} />
          <Route exact path={`/mmdp/wishlist`} component={WishList} />
          <Route exact path={`/mmdp/contact`} component={Contact} />
          <Route exact path={`/mmdp/aboutus`} component={AboutUs} />
          <Route exact path={`/mmdp/category`} component={CategoryDataList} />
          <Route exact path={`/mmdp/comingsoon`} component={ComingSoon} />
          <Route exact path={`/mmdp/shoppingcart`} component={ShoppingCart} />
          <Route exact path={`/mmdp/collections`} component={Collections} />

          <Route exact path={`/mmdp/profile`} component={Profile} />
          <Route exact path={`/mmdp/loginPhone`} component={LoginPhone} />
          <Route exact path={`/mmdp/orders`} component={OrderHistory} />
          <Route exact path={`/mmdp/addresses`} component={MyAddress} />
          <Route exact path={`/mmdp/checkout`} component={CheckOut} />
          <Route exact path={`/mmdp/help`} component={HelpCenter} />
          <Route
            exact
            path={`/mmdp/order-detail/:saleNo/:shopId/:businessId`}
            component={OrderDetail}
          />

          <Route exact path={`/mmdp/discover`} component={Discover} />
          <Redirect from={`/`} to={`/mmdp`} />

          {/* <Route exact path={`/`} component={Home} />
          <Route exact path={`/shopList/:bizTypeId`} component={ShopList} />
          <Route exact path={`/shopList/shopProfile/:businessId/:shopId`} component={ShopDetail} />
          <Route exact path={`/business/:businessId/:shopId`} component={MenuPage} />
          <Route exact path={`/productDetail/:businessId/:shopId/:itemId`} component={ItemDetail} />
          <Route exact path={`/allBizType`} component={AllBizType} />
          <Route exact path={`/search`} component={Search} />
          <Route exact path={`/itemSearch`} component={ItemSearch} />
          <Route exact path={`/shopSearch`} component={ShopSearch} />
          <Route exact path={`/wishlist`} component={WishList} />
          <Route exact path={`/contact`} component={Contact} />
          <Route exact path={`/aboutus`} component={AboutUs} />
          <Route exact path={`/category`} component={CategoryDataList} />
          <Route exact path={`/comingsoon`} component={ComingSoon} />
          <Route exact path={`/shoppingcart`} component={ShoppingCart} />
          <Route exact path={`/collections`} component={Collections} />

          
          <Route exact path={`/profile`} component={Profile} />
          <Route exact path={`/loginPhone`} component={LoginPhone} />
          <Route exact path={`/orders`} component={OrderHistory} />
          <Route exact path={`/addresses`} component={MyAddress} />
          <Route exact path={`/checkout`} component={CheckOut} />
          <Route exact path={`/order-detail/:saleNo/:shopId/:businessId`} component={OrderDetail} />

          <Route exact path={`/discover`} component={Discover} /> */}
        </Switch>
      </header>
    </div>
  );
}

export default App;
