import React from 'react'
import './styles.css'

class Profile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fb_token : "",
            registeredUser: false,
            customerId: "",
            customerName: "",
            customerEmail: "",
            customerProfileImg: "",
            phone: "",
            address: "",
            accountType: "",
            loading: false,
        }
    }

    render() {
        return(
            <div>
                <div className="profile_container"></div>

                <div className="wrapper">
                    <div className="help_detail">
                        <div className="block phone">
                            <span className="title">
                                <i className="fa fa-phone-square" aria-hidden="true"></i>
                                <span className="help_title">Call Us</span>
                            </span>
                            
                            <div className="list"><span className="mini_title">Ooredoo Users: </span> <span className="data">09977779295</span></div>
                            <div className="list"><span className="mini_title">MPT Users: </span> <span className="data">09881336479</span></div>
                            <div className="list"><span className="mini_title">Mytel Users: </span> <span className="data">09691883637</span></div>
                            <div className="list"><span className="mini_title">Telenor Users: </span> <span className="data">09791159664</span></div>
                        </div>
                        <div className="block email">
                            <span className="title">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <span className="help_title">Email Us</span>
                            </span>
                            <div className="list">
                                <span className="data">assist.mmdigitalplatform@gmail.com</span>
                            </div>
                        </div>
                        <div className="block browse">
                            <span className="title">
                                <i className="fa fa-globe" aria-hidden="true"></i>
                                <span className="help_title">Browse Us</span>
                            </span>
                            <div className="list">
                                <a href="https://www.mmdigitalplatform.com" target="_blink"><span className="data">https://www.mmdigitalplatform.com</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile