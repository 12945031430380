import React from 'react';
import ImageGallery from 'react-image-gallery';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import MetaForShare from '../../widgets/MetaForShare';

import './styles.css';
import Loader from '../../widgets/Loader';

class ItemDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      showImage: true,
      item: [],
      images: [],
      itemVariants: [],
      properties: [],
      qty: 1,

      singleVariants: [],
      multipleVariants: [],
      count: 0,
      proceed: false,
      showPopup: false,
      clearCart: false,
      businessId: '',
      shopId: '',
      parentId: '',
      itemByProps: [],
      pids: [],
      loadProperties: false,
      defaultProperties: [],
      note: '',
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      businessId: params.businessId,
      shopId: params.shopId,
      parentId: params.parentId,
    });
    this.props.getItemDetailAction(
      params.itemId,
      params.businessId,
      params.shopId,
      params.parentId
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.itemDetail)
      if (nextProps.itemDetail.data !== prevState.item) {
        return { itemDetail: nextProps.itemDetail };
      }

    if (nextProps.itemDetailByProps !== prevState.itemByProps) {
      return { itemDetailByProps: nextProps.itemDetailByProps };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.itemDetail !== this.props.itemDetail) {
      if (this.props.itemDetail.code === '200') {
        let count = 0;
        if (this.props.itemDetail.data && this.props.itemDetail.data.variants)
          for (let vari of this.props.itemDetail.data.variants) {
            if (vari.multiple === 0) {
              count++;
            }
          }

        let images = JSON.parse(this.props.itemDetail.data.pic_or_color);
        let imgArr = [];
        if (images.length > 0) {
          for (let img of images) {
            let temp = {
              original: img,
            };
            imgArr.push(temp);
          }
        } else {
          let temp = {
            original: require('../../assets/img/no-image.jpg'),
          };
          imgArr.push(temp);
        }

        if (count === 0) {
          this.setState({
            proceed: true,
          });
        }

        let defaultProperties =
          this.props.itemDetail.data && this.props.itemDetail.data.properties
            ? this.props.itemDetail.data.properties.split('/')
            : [];

        this.setState({
          item: this.props.itemDetail.data,
          itemVariants: this.props.itemDetail.data.variants,
          properties: this.props.itemDetail.data.prop_choices
            ? this.props.itemDetail.data.prop_choices
            : [],
          // pids: this.props.itemDetail.data.prop_choices ? this.props.itemDetail.data.prop_choices.map(property => property.pid) : [],
          images: imgArr,
          count,
          defaultProperties,
        });
      }
    }

    if (prevProps.itemDetailByProps !== this.props.itemDetailByProps) {
      if (this.props.itemDetailByProps.message === 'property') {
        let tempProperty = this.state.properties;
        for (let index in tempProperty) {
          for (let changeProperty of this.props.itemDetailByProps.data) {
            if (changeProperty.pid === tempProperty[index].pid) {
              tempProperty[index] = changeProperty;
              break;
            }
          }
        }
        this.setState({
          itemByProps: this.props.itemDetailByProps,
          properties: tempProperty,
          loadProperties: false,
        });
      } else {
        let defaultProperties =
          this.props.itemDetailByProps.data &&
          this.props.itemDetailByProps.data.properties
            ? this.props.itemDetailByProps.data.properties.split('/')
            : [];

        let images = JSON.parse(this.props.itemDetailByProps.data.pic_or_color);
        let imgArr = [];
        if (images.length > 0) {
          for (let img of images) {
            let temp = {
              original: img,
            };
            imgArr.push(temp);
          }
        } else {
          let temp = {
            original: require('../../assets/img/no-image.jpg'),
          };
          imgArr.push(temp);
        }
        this.setState({
          item: this.props.itemDetailByProps.data,
          properties: this.props.itemDetailByProps.data.prop_choices
            ? this.props.itemDetailByProps.data.prop_choices
            : [],
          pids: [],
          loadProperties: false,
          defaultProperties,
          images: imgArr,
        });
      }
    }

    if (prevState.item !== this.state.item) {
      // window.scroll(0,0)
    }
  }

  showImage = () => {
    this.setState({
      showImage: true,
    });
  };

  handleSingle = (e) => {
    let vari = e.target.value.split('_');

    let variId = vari[0];
    let choosenId = vari[1];
    let variName = vari[2];
    let choiceName = vari[3];
    let price = vari[4];

    let variants = [...this.state.singleVariants];
    let inserted = false;

    for (let i = 0; i < variants.length; i++) {
      if (variants[i].variantId === variId) {
        variants[i].choiceId = choosenId;
        variants[i].choiceName = choiceName;
        variants[i].variantName = variName;
        variants[i].price = price;
        inserted = true;
      }
    }

    if (!inserted) {
      let temp = {
        variantId: variId,
        choiceId: choosenId,
        choiceName: choiceName,
        variantName: variName,
        price: price,
      };
      variants.push(temp);
    }

    if (variants.length === this.state.count) {
      this.setState({
        proceed: true,
      });
    }

    this.setState({
      singleVariants: variants,
    });
  };

  handleMultiple = (e) => {
    let vari = e.target.value.split('_');

    let variId = vari[0];
    let choosenId = vari[1];
    let variName = vari[2];
    let choiceName = vari[3];
    let price = vari[4];

    let variants = [...this.state.multipleVariants];
    let inserted = false;

    for (let i = 0; i < variants.length; i++) {
      if (variants[i].variantId === variId) {
        if (variants[i].choiceId === choosenId) {
          variants.splice(i, 1);
          inserted = true;
        }
      }
    }

    if (!inserted) {
      let temp = {
        variantId: variId,
        choiceId: choosenId,
        choiceName: choiceName,
        variantName: variName,
        price: price,
      };
      variants.push(temp);
    }

    this.setState({
      multipleVariants: variants,
    });
  };

  handleInput = (key, e) => {
    if (key === 'qty') {
      if (e.target.value === '') {
        this.setState({
          [key]: 1,
        });
      } else {
        this.setState({
          [key]: e.target.value,
        });
      }
    } else {
      this.setState({
        [key]: e.target.value,
      });
    }
  };

  addToCart = (product, clearCart) => {
    let currentShopId = localStorage.getItem('shop_id');
    let currenBusinessId = localStorage.getItem('businessId');
    console.log('currentShopId =>', currentShopId);
    console.log(
      'this.props.match.params.shopId =>',
      this.props.match.params.shopId
    );
    console.log(
      'this.props.match.params.businessId =>',
      this.props.match.params.businessId
    );
    console.log('currenBusinessId =>', currenBusinessId);
    if (
      currentShopId &&
      (parseInt(this.props.match.params.shopId) !== parseInt(currentShopId) ||
        parseInt(this.props.match.params.businessId) !==
          parseInt(currenBusinessId))
    ) {
      console.log('IF');
      if (clearCart) {
        localStorage.removeItem('cart_item');
        localStorage.setItem('shop_id', this.props.match.params.shopId);
        localStorage.setItem('businessId', this.props.match.params.businessId);

        let itemName = product.item_name,
          itemId = product.item_id,
          currency = product.price_currency_code,
          price = product.price,
          photo = product.pic_or_color;
        let cartData = [];
        let data = {
          item_name: itemName,
          itemId: itemId,
          item_currency: currency,
          item_price: price,
          qty: this.state.qty,
          note: this.state.note,
          photo,
          discount: product.discount,
          tax: product.tax,

          variants: this.state.singleVariants.concat(
            this.state.multipleVariants
          ),
        };
        let localData = localStorage.getItem('cart_item');
        if (localData) {
          cartData = JSON.parse(localData);
          if (cartData && cartData.length > 0) {
            for (let cart of cartData) {
              if (cart.itemId === itemId) {
                if (cart.note === this.state.note) {
                  if (!cart.variants.length > 0) {
                    cart.qty = parseInt(cart.qty) + 1;
                    localStorage.setItem('cart_item', JSON.stringify(cartData));
                    toast.success('Item Added to Cart');
                    return;
                  } else {
                    for (let variant of cart.variants) {
                      for (let currVaraint of this.state.singleVariants.concat(
                        this.state.multipleVariants
                      )) {
                        if (variant.choiceId === currVaraint.choiceId) {
                          cart.qty = parseInt(cart.qty) + this.state.qty;
                          this.setState({ qty: 1, note: '' });
                          localStorage.setItem(
                            'cart_item',
                            JSON.stringify(cartData)
                          );
                          toast.success('Item Added to Cart');
                          return;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        cartData.push(data);
        localStorage.setItem('cart_item', JSON.stringify(cartData));
        localStorage.setItem('shop_id', this.props.match.params.shopId);
        localStorage.setItem('businessId', this.props.match.params.businessId);
        toast.success('Item Added to Cart');
        this.props.getShoppingCartCount();
        this.setState({
          qty: 1,
          note: '',
        });
        if (this.state.itemVariants && this.state.itemVariants.length > 0) {
          document
            .querySelectorAll('input[type=checkbox]')
            .forEach((el) => (el.checked = false));
          document
            .querySelectorAll('input[type=radio]')
            .forEach((el) => (el.checked = false));
        }
      } else {
        this.togglePopup();
      }
    } else {
      console.log('Else');
      let itemName = product.item_name,
        itemId = product.item_id,
        currency = product.price_currency_code,
        price = product.price,
        photo = product.pic_or_color;
      let cartData = [];
      let data = {
        item_name: itemName,
        itemId: itemId,
        item_currency: currency,
        item_price: price,
        qty: this.state.qty,
        note: this.state.note ? this.state.note : '',
        photo,
        discount: product.discount,
        tax: product.tax,

        variants: this.state.singleVariants.concat(this.state.multipleVariants),
        properties: this.state.defaultProperties,
      };
      let localData = localStorage.getItem('cart_item');
      if (localData) {
        cartData = JSON.parse(localData);
        if (cartData && cartData.length > 0) {
          for (let cart of cartData) {
            if (cart.itemId === itemId) {
              if (cart.note === this.state.note) {
                if (!cart.variants.length > 0) {
                  cart.qty = parseInt(cart.qty) + this.state.qty;
                  this.setState({ qty: 1, note: '' });
                  localStorage.setItem('cart_item', JSON.stringify(cartData));
                  toast.success('Item Added to Cart');
                  return;
                } else {
                  let currentVariants = this.state.singleVariants.concat(
                    this.state.multipleVariants
                  );
                  if (currentVariants.length === cart.variants.length) {
                    let same = true;
                    for (let currVaraint of currentVariants) {
                      for (let i = 0; i < cart.variants.length; i++) {
                        if (
                          cart.variants[i].choiceId === currVaraint.choiceId &&
                          cart.variants[i].variantId === currVaraint.variantId
                        )
                          break;
                        if (i + 1 === currentVariants.length) same = false;
                      }
                    }
                    if (same) {
                      cart.qty = parseInt(cart.qty) + this.state.qty;
                      this.setState({ qty: 1, note: '' });
                      localStorage.setItem(
                        'cart_item',
                        JSON.stringify(cartData)
                      );
                      toast.success('Item Added to Cart');
                      this.props.getShoppingCartCount();
                      this.setState({
                        qty: 1,
                        note: '',
                      });
                      if (
                        this.state.itemVariants &&
                        this.state.itemVariants.length > 0
                      ) {
                        document
                          .querySelectorAll('input[type=checkbox]')
                          .forEach((el) => (el.checked = false));
                        document
                          .querySelectorAll('input[type=radio]')
                          .forEach((el) => (el.checked = false));
                      }
                      this.setState({
                        multipleVariants: [],
                      });
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }
      cartData.push(data);
      this.setState({
        multipleVariants: [],
      });
      localStorage.setItem('cart_item', JSON.stringify(cartData));
      localStorage.setItem('shop_id', this.props.match.params.shopId);
      localStorage.setItem('businessId', this.props.match.params.businessId);
      toast.success('Item Added to Cart');
      this.props.getShoppingCartCount();
      this.setState({
        qty: 1,
        note: '',
      });
      if (this.state.itemVariants && this.state.itemVariants.length > 0) {
        document
          .querySelectorAll('input[type=checkbox]')
          .forEach((el) => (el.checked = false));
        document
          .querySelectorAll('input[type=radio]')
          .forEach((el) => (el.checked = false));
      }
    }
  };

  addToWishList = (product) => {
    let itemName = product.item_name,
      itemId = product.item_id,
      currency = product.price_currency_code,
      price = product.price,
      photo = product.pic_or_color;
    let cartData = [];
    let data = {
      item_name: itemName,
      item_id: itemId,
      item_currency: currency,
      item_price: price,
      qty: 1,
      note: this.state.note,
      photo,
      url: this.props.match.url,
    };
    let localData = localStorage.getItem('wishlist_item');
    if (localData) {
      cartData = JSON.parse(localData);
      if (cartData && cartData.length > 0) {
        for (let cart of cartData) {
          if (cart.item_id === itemId) {
            toast.warning('Item is already in Wish List');
            return;
          }
        }
      }
    }
    cartData.push(data);
    localStorage.setItem('wishlist_item', JSON.stringify(cartData));
    toast.success('Item Added to Wish List');
    // props.getWishListCount()
  };

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  confirmProceed = () => {
    this.setState({
      showPopup: false,
      clearCart: true,
    });
    this.addToCart(this.state.item, true);
  };

  addQty = () => {
    this.setState({
      qty: this.state.qty + 1,
    });
  };

  minusQty = () => {
    if (this.state.qty > 1)
      this.setState({
        qty: this.state.qty - 1,
      });
  };

  handlePropertyChange = (pid, vid, property, itemId) => {
    let defaultProperties =
      this.state.defaultProperties.length === this.state.properties.length
        ? []
        : this.state.defaultProperties;
    let prevPids = this.state.pids;
    let uniqPids = prevPids.filter((prevPid) => prevPid !== pid);
    uniqPids.push(pid);
    this.setState({
      pids: uniqPids,
    });
    let finished = false;
    if (defaultProperties.length > 0) {
      for (let i = 0; i < property.vals.length; i++) {
        for (let j = 0; j < defaultProperties.length; j++) {
          if (property.vals[i].vid === defaultProperties[j]) {
            defaultProperties.splice(j, 1);
            finished = true;
            break;
          }
        }
        if (finished) break;
      }
    }
    defaultProperties.push(vid);
    this.setState({
      loadProperties: true,
      defaultProperties,
      proceed: defaultProperties.length === this.state.properties.length,
    });
    this.props.findItemByPropsAction(
      uniqPids,
      itemId,
      this.state.properties.length,
      this.state.parentId,
      this.state.shopId,
      this.state.businessId
    );
  };

  render() {
    return (
      <div className="bd bottom-space">
        {this.state.item && this.state.item.item_id ? (
          <div>
            <MetaForShare
              title={`MMDP ${this.state.item.item_name}`}
              url={`http://localhost:3000/${this.props.match.url}`}
              img={this.state.images[0]}
              desc="Shop List of the Glories"
              siteName="Glories"
            />
            <div className="app_bar ms_row border_bottom">
              <div className="col_2">
                <button onClick={(e) => this.props.history.goBack()}>
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
              </div>
              <div className="search_section col_10">
                <h2 style={{ fontSize: '22px' }}>Item Details</h2>
              </div>
            </div>

            <div style={{ height: '40px', width: '100%' }}></div>

            <div className="product_detail_wrapper">
              <div className="product_gallery">
                <figure className="product_gallery_wrapper">
                  <div className="product_img_container">
                    <ImageGallery
                      items={this.state.images}
                      showNav={false}
                      autoPlay={true}
                      showThumbnails={false}
                      showPlayButton={false}
                    />
                  </div>
                </figure>
              </div>

              <div className="product-summary">
                <div className="product-info">
                  <div className="product-price-wrapper">
                    <div className="product-price">
                      <div className="price-bar">
                        <span className="price">
                          Ks {this.state.item.price}{' '}
                        </span>
                        {this.state.item.discount > 0 ? (
                          <span className="_discount">
                            {' '}
                            ({this.state.item.discount}% off)
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sub-header">
                    <h2>Sold By: {this.state.item.shop_name}</h2>
                  </div>
                </div>

                <div className="divider-block"></div>

                <div className="product-info">
                  <div className="product-title">
                    {this.state.item.item_name}
                  </div>
                  <div className="sub-header">
                    <h2>{this.state.item.category_name}</h2>
                  </div>
                </div>

                <div className="divider-block"></div>

                <div className="product-info">
                  {this.state.properties && this.state.properties.length > 0 ? (
                    <div className="product_meta web_view_product_meta prop-single">
                      {this.state.loadProperties ? (
                        <div className="loader">
                          <span>
                            <div
                              className="spinner-border text-success"
                              role="status"
                              style={{ width: '3rem', height: '3rem' }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.properties.map((property) => (
                        <div key={property.pid} className="property_wrapper">
                          <div className="property_title">{property.pname}</div>
                          <div className="single">
                            {property.vals && property.pname === 'Color'
                              ? property.vals.map((value) =>
                                  value.vid ? (
                                    <label key={value.vid}>
                                      {/* <input type="radio" onClick={e => this.handlePropertyChange(property.pid, value.vid, value.item_id)} name={`${property.pid}`} value={`${value.vid}`} /> */}
                                      <input
                                        type="radio"
                                        checked={this.state.defaultProperties.includes(
                                          value.vid
                                        )}
                                        onClick={(e) =>
                                          this.handlePropertyChange(
                                            property.pid,
                                            value.vid,
                                            property,
                                            value.item_id
                                          )
                                        }
                                        name={`${property.pid}`}
                                        value={`${value.vid}`}
                                      />
                                      <span
                                        className="single_title color"
                                        style={{ backgroundColor: value.vid }}
                                      ></span>
                                    </label>
                                  ) : (
                                    ''
                                  )
                                )
                              : property.vals.map((value) =>
                                  value.vid ? (
                                    <label key={value.vid}>
                                      <input
                                        type="radio"
                                        checked={this.state.defaultProperties.includes(
                                          value.vid
                                        )}
                                        onClick={(e) =>
                                          this.handlePropertyChange(
                                            property.pid,
                                            value.vid,
                                            property,
                                            value.item_id
                                          )
                                        }
                                        name={`${property.pid}`}
                                        value={`${value.vid}`}
                                      />
                                      <span
                                        className="single_title"
                                        style={{ backgroundColor: value.vid }}
                                      >
                                        <span className="mini_title">
                                          {value.vid}
                                        </span>
                                        {/* <span className="mini_title">{value.price} Ks</span> */}
                                      </span>
                                    </label>
                                  ) : (
                                    ''
                                  )
                                )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                
                {this.state.itemVariants && this.state.itemVariants.length > 0 ? 
                  <div className="divider-block"></div>
                : ""}

                <div className="product-info">
                  {this.state.itemVariants &&
                    this.state.itemVariants.length > 0 ? (
                      <div className="product_meta web_view_product_meta">
                        {this.state.itemVariants.map((variant) => (
                          <div key={variant.variant_id}>
                            <div className="variant_title">
                              {variant.variant_name}
                            </div>
                            {variant.multiple === 0 ? (
                              <div className="single">
                                {variant.choices.map((choice) => (
                                  <label key={choice.choice_id}>
                                    <input
                                      type="radio"
                                      onClick={this.handleSingle}
                                      name={`${variant.variant_id}_${variant.variant_name}`}
                                      value={`${variant.variant_id}_${choice.choice_id}_${variant.variant_name}_${choice.choice}_${choice.price}`}
                                    />
                                    <span className="single_title">
                                      <span className="mini_title">
                                        {choice.choice}
                                      </span>
                                      <span className="mini_title">
                                        {choice.price} Ks
                                      </span>
                                    </span>
                                  </label>
                                ))}
                              </div>
                            ) : (
                              <div className="single">
                                {variant.choices.map((choice) => (
                                  <label key={choice.choice_id}>
                                    <input
                                      type="checkbox"
                                      onClick={this.handleMultiple}
                                      name={`${variant.variant_id}_${variant.variant_name}`}
                                      value={`${variant.variant_id}_${choice.choice_id}_${variant.variant_name}_${choice.choice}_${choice.price}`}
                                    />
                                    <span className="single_title">
                                      <span className="mini_title">
                                        {choice.choice}
                                      </span>
                                      <span className="mini_title">
                                        {choice.price} Ks
                                      </span>
                                    </span>
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                    ''
                  )}
                </div>

                <div className="divider-block"></div>

                <div className='product-info'>
                    
                  <div className="form-group" style={{ clear: 'both', paddingTop: "10px" }}>
                    <label htmlFor="spercialInstruction">
                      Special Instruction
                    </label>
                    <textarea
                      value={this.state.note}
                      className="form-control"
                      id="spercialInstruction"
                      rows="3"
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 200);
                      }}
                      onChange={(e) => this.handleInput('note', e)}
                    ></textarea>
                  </div>

                  <div className="action">
                    <div className="quantity">
                      <label
                        className="screen-reader-text"
                        htmlFor="quantity_5cd96a418e8ad"
                      >
                        Quantity
                      </label>
                      <input
                        type="number"
                        className="input-text qty text"
                        value={this.state.qty}
                        onChange={(e) => this.handleInput('qty', e)}
                        title="Qty"
                        // disabled
                      />
                      <div className="quantity-nav">
                        <span
                          className="quantity-button quantity-up"
                          onClick={this.addQty}
                        >
                          +
                        </span>
                        <span
                          className="quantity-button quantity-down"
                          onClick={this.minusQty}
                        >
                          -
                        </span>
                      </div>
                    </div>
                    {this.state.proceed ? (
                      <button
                        name="add-to-cart"
                        disabled={
                          !this.state.defaultProperties.length ===
                          this.state.properties.length
                        }
                        onClick={() => this.addToCart(this.state.item)}
                        className="add_to_cart"
                      >
                        Add to cart
                      </button>
                    ) : (
                      <button
                        name="add-to-cart"
                        disabled
                        className="add_to_cart no_act"
                      >
                        Add to cart
                      </button>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        {this.state.showPopup ? (
          <div className="popup">
            <div className="popup_inner">
              <span className="title">Alert!</span>
              <h1>
                You have chosen an item from different shop. If you proceed, the
                existing items in the cart will be removed.
              </h1>
              <div>
                <button onClick={this.togglePopup}>Cancel</button>
                <button onClick={this.confirmProceed} className="proceed">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    itemDetail: state.itemDetail,
    itemDetailByProps: state.itemDetailByProps,
  };
}

export default connect(mapStateToProps, actions)(ItemDetail);
