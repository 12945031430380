import React from 'react'
import { Link } from 'react-router-dom'
import ImageGallery from 'react-image-gallery';

import MetaForShare from '../../widgets/MetaForShare'
import { addShopToCollection, getCollectionList, getShopDetails } from '../../api';
import { Button, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from '../../widgets/Loader';

import { GoogleMap, withGoogleMap, Marker } from 'react-google-maps'
import './styles.css'

const numberToDay = {
    "1": "Mon",
    "2": "Tue",
    "3": "Wed",
    "4": "Thu",
    "5": "Fri",
    "6": "Sat",
    "0": "Sun",
}

class Map extends React.Component {
    render() {
        const GoogleMapExample = withGoogleMap(props => (
            <GoogleMap
                defaultZoom={18}
                defaultCenter={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) }}
            >
                {!this.props.noMaker ?
                    <Marker position={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) }} />
                    : ""}
            </GoogleMap>
        ));
        return (
            <div style={{clear: "both"}}>
                <GoogleMapExample
                    containerElement={<div style={{ height: `300px`, width: '100%', marginTop: "10px" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        );
    }
};

class ShopDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            images: [],
            shopName: "",
            houseNo: "",
            street: "",
            township: "",
            description: "",
            highlight: 0,
            modal: false,
            nestedModal: false,
            claseAll: false,
            newCollectionName: "",
            notFound: false,
            notFoundMessage: "",
            collectionList: [],
            collectionError: false,
            collectionErrorMessage: "",
            businessHour: [],
            lat: 16.806477,
            lng: 96.136500,
            noMaker: false
        }
    }


    async componentDidMount() {
        this.refreshShopDetail()
        this.refreshCollection()
    }

    refreshCollection = async () => {
        let collectionList = await getCollectionList()

        if(collectionList.code === '200') {
            this.setState({
                collectionList: collectionList.data,
                collectionError: false,
                collectionErrorMessage: ""
            })
        } else if(collectionList.code === '403') {
            this.setState({
                collectionError: true,
                collectionErrorMessage: "Please Login to add in collection"
            })
        } else {
            this.setState({
                collectionError: true,
                collectionErrorMessage: collectionList.message
            })
        }
    }

    refreshShopDetail = async () => {
        const { match: { params } } = this.props;
        let shopDetail = await getShopDetails(params.shopId, params.businessId)
        if (shopDetail.code === '200') {
            let images = JSON.parse(shopDetail.data[0].shop_img_url)
            let imgArr = []
            if (images.length > 0) {
                for (let img of images) {
                    let temp = {
                        original: img
                    }
                    imgArr.push(temp)
                }
            } else {
                let temp = {
                    original: require("../../assets/img/no-image.jpg")
                }
                imgArr.push(temp)
            }
            this.setState({
                images: imgArr,
                shopName: shopDetail.data[0].shop_name,
                houseNo: shopDetail.data[0].house_no,
                street: shopDetail.data[0].street,
                township: shopDetail.data[0].township,
                description: shopDetail.data[0].description,
                highlight: shopDetail.data[0].highlight,
                businessHour: shopDetail.data[0].available_time ? shopDetail.data[0].available_time : [],
                lat: shopDetail.data[0].lat ? shopDetail.data[0].lat : 16.806477,
                lng: shopDetail.data[0].lon ? shopDetail.data[0].lon : 96.136500,
                noMaker: !shopDetail.data[0].lat || !shopDetail.data[0].lon ? true : false,
                notFoundMessage: "",
                notFound: false
            })
        } else {
            this.setState({
                notFound: true,
                notFoundMessage: shopDetail.message
            })
        }
    }


    setModalOpen = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    toggleNested = () => {
        this.setState({
            nestedModal: !this.state.nestedModal,
            closeAll: false,
            newCollectionName: "",
        })
    }

    handleNewCollectionName = (e) => {
        this.setState({
            newCollectionName: e.target.value
        })
    }

    addNewCollection = async () => {
        let newCollection = await addShopToCollection("", this.state.newCollectionName, this.props.match.params.shopId, this.props.match.params.businessId)
        if (newCollection.code === '200') {
            this.setState({
                nestedModal: !this.state.nestedModal,
                closeAll: true,
                newCollectionName: "",
            })
            this.refreshCollection()
            this.refreshShopDetail()
            toast.success('New collection added!')
        } else {
            toast.error(newCollection.message)
        }
    }

    setToCollection = async (id) => {
        let newCollection = await addShopToCollection(id, "", this.props.match.params.shopId, this.props.match.params.businessId)
        if (newCollection.code === '200') {
            this.setState({
                modal: false,
                closeAll: true,
                newCollectionName: "",
            })
            this.refreshShopDetail()
            toast.success('Added to collection success')
        } else {
            toast.error(newCollection.message)
        }
    }

    render() {

        return (

            <div className='bottom-space'>
                <MetaForShare
                    title="MMDP - Shop Detail"
                    url={`http://localhost:3000/${this.props.match.url}`}
                    img="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
                    desc="Shop Detail of the Glories"
                    siteName="Glories"
                />
                <div className="app_bar ms_row border_bottom">
                    <div className="col_2">
                        <button onClick={e => this.props.history.goBack()}><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <div className="search_section col_10">
                        <h2 style={{ fontSize: "22px" }}>Shop Details</h2>
                    </div>
                </div>

                <div style={{ height: "40px", width: "100%" }}></div>

                { !this.state.notFound ?
                    this.state.shopName ?
                        <div className="product_detail_wrapper">
                            <div className="product_gallery">
                                <figure className="product_gallery_wrapper">
                                    <div className="product_img_container">
                                        <ImageGallery showFullscreenButton={false} items={this.state.images} showNav={false} autoPlay={true} showThumbnails={false} showPlayButton={false} />
                                    </div>
                                </figure>
                            </div>

                            <div className="product-summary">
                                <div className="product-info">
                                    <div className="product-title">
                                        {this.state.shopName}
                                    </div>
                                    <div className="sub-header">
                                        <h2>
                                            {this.state.houseNo ? "No - " + this.state.houseNo + ", " : ""}
                                            {this.state.street ? this.state.street + ", " : ""}
                                            {this.state.township ? this.state.township : ""}
                                        </h2>
                                    </div>

                                    <div className='shop-detail-control'>
                                        <div className="control-icon-wrapper">
                                            <Link to={`/mmdp/business/${this.props.match.params.businessId}/${this.props.match.params.shopId}`}>
                                                <span className="btn-inner--icon">
                                                    <i className="fa fa-list"></i>
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="control-icon-wrapper">
                                            
                                            {this.state.highlight === 0 ?
                                                <span onClick={this.setModalOpen}>
                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                </span>
                                                :
                                                <Link to="/mmdp/collections">
                                                    <span style={{color: 'rgb(0, 150, 136)'}}>
                                                        <i className="fa fa-heart" aria-hidden="true"></i>
                                                    </span>
                                                </Link>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className="divider-block"></div>

                                <div className="product-info">
                                    <div className="product-title">
                                        Business Info
                                    </div>
                                    <div className="sub-header">
                                        <h2>{this.state.description ? this.state.description : "...."}</h2>
                                    </div>
                                </div>

                                <div className="divider-block"></div>

                                {this.state.businessHour && this.state.businessHour.length > 0 &&
                                    <div className="product-info">
                                        <div className="product-title">
                                            Business Hour
                                        </div>
                                        
                                        <table className='bh-table'>
                                            <tbody>
                                                {
                                                    this.state.businessHour.map(businessHour => (
                                                        <tr key={businessHour.d}>
                                                            <td className='bh-date'>{numberToDay[businessHour.d]}</td>
                                                            <td className='bh-open'>{businessHour.o}</td>
                                                            <td className='bh-hypen'>-</td>
                                                            <td className='bh-colse'>{businessHour.c}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }


                                <div className="divider-block"></div>

                                <div className="product-info">

                                <Map lat={this.state.lat} lng={this.state.lng} noMaker={this.state.noMaker} />
                                </div>

                            </div>

                        </div>

                        : <Loader />
                    :
                    <div>
                        <div className="not-found">
                            <p className="not-found-letter">{this.state.notFoundMessage}</p>
                        </div>
                    </div>
                }

                <Modal isOpen={this.state.modal} backdrop="static" toggle={this.setModalOpen} keyboard={false}>
                    <ModalHeader toggle={this.setModalOpen}>Add this shop to your collections</ModalHeader>
                    <ModalBody>
                        <div>
                            {!this.state.collectionError ?
                                <ListGroup>
                                    <ListGroupItem tag="button" action color="success" onClick={this.toggleNested}>+ Add New Collection</ListGroupItem>
                                    {this.state.collectionList && this.state.collectionList.length > 0 ?
                                        this.state.collectionList.map(collection => (
                                            <ListGroupItem key={collection.collection_id} tag="button" action onClick={e => this.setToCollection(collection.collection_id)}>{collection.collection_name}</ListGroupItem>
                                        ))
                                    : ""}
                                </ListGroup>
                                : <div>{this.state.collectionErrorMessage}</div>
                            }
                        </div>

                        <Modal isOpen={this.state.nestedModal} backdrop="static" keyboard={false} toggle={this.toggleNested} onClosed={this.state.closeAll ? this.setModalOpen : undefined}>
                            <ModalHeader>New Collection</ModalHeader>
                            <ModalBody>
                                <Input 
                                    placeholder="Enter name for collection" 
                                    onKeyUp={e => this.handleNewCollectionName(e)} 
                                    onInput={e => {
                                        e.target.value = e.target.value.slice(0, 100)
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.addNewCollection}>Create And Add</Button>{' '}
                                <Button color="secondary" onClick={this.toggleNested}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.setModalOpen}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}

export default ShopDetail