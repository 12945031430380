import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './styles.css';
import * as actions from '../../actions';
import noImage from '../../assets/img/no-image.jpg';

const Product = (props) => {
  return (
    <div
      className={`${props.layout === 'list' ? 'product_list' : 'item_list'}`}
      style={{ padding: 0 }}
    >
      <Link
        to={`/mmdp/productDetail/${props.businessId}/${props.shopId}/${props.product.parent_id}/${props.product.item_id}`}
      >
        <div className="product_inner">
          <div
            className={`${
              props.layout === 'list' ? 'product_list_head' : 'product_head'
            }`}
          >
            <div className="product_image">
              {props.imgs && props.imgs.length > 0 ? (
                <img src={props.imgs[0]} alt="prodct" />
              ) : (
                <img src={noImage} alt="prodct" />
              )}
              {props.product.discount > 0 ? (
                <span className="discount">{props.product.discount}% off</span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className={`${
              props.layout === 'list' ? 'product_list_content_cont' : ''
            }`}
          >
            <div
              className={`${
                props.layout === 'list'
                  ? 'product_list_content'
                  : 'product_content'
              }`}
            >
              <h3 className="product_title">
                {props.product ? props.product.item_name : ''}
              </h3>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'block',
                }}
              >
                {props.product ? props.product.shop_name : ''}
              </span>
              <span className="price">
                {props.product ? props.product.price_currency_code : ''}{' '}
                {props.product ? `${props.product.price} Ks` : ''}
              </span>
              {/* <span className="price">{props.product.category_name ? props.product.category_name : ""}</span> */}
            </div>
            {/* <div className={`${props.layout === 'list' ? "product_list_action action" : "action"}`}>
                    <button className="add_to_cart" onClick={(e) => addToCart(props.product)}></button>
                    <button className={`add_to_wishlist`} onClick={e => addToWishList(props.product)}></button>
                </div> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    preDefinedCategories: state.preDefinedCategories,
    wishlistCount: state.wishlistCount,
    shoppingCartCount: state.shoppingCartCount,
  };
}

export default connect(mapStateToProps, actions)(Product);
