import React from 'react'

import './styles.css'
import { checkLogin, getSaleList } from '../../api'
import LoginFacebook from '../../widgets/Facebook'
import { Link } from 'react-router-dom'

class OrderHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            login: true,
            orders: []
        }
    }

    async componentDidMount() {
        let login = await checkLogin()
        if(login) {
            let orders = await getSaleList()
            if(orders.code === '200') {
                this.setState({
                    orders: orders.data
                })
            } else {
                this.setState({
                    login
                })
            }
        }
        this.setState({
            login
        })
    }

    editCustomerDetail = async () => {
        this.setState({
            login: true,
        })
    }

    render() {
        return this.state.login ? (
          <div className="bottom-space">
            <div className="app_bar ms_row border_bottom">
              <div className="col_2 t_left">
                <button>
                  <i
                    onClick={(e) => this.props.history.goBack()}
                    className="fa fa-chevron-left"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div className="search_section col_8">
                <h2
                  style={{
                    fontSize: '1.2em',
                  }}
                >
                  My Orders
                </h2>
              </div>
            </div>

            <div style={{ height: '40px' }}></div>

            {this.state.orders && this.state.orders.length > 0 ? (
              this.state.orders.map((order, ind) => (
                <Link
                  to={`/mmdp/order-detail/${order.sale_no}/${order.shop_id}/${order.business_id}`}
                  className="sale_list_item"
                >
                  <div className="product-info">
                    <div className="product-title">
                      <span className="name"> {order.shop_name} </span>
                    </div>
                    <div className="sub-header">
                      <h2>
                        <span className="sale_no">
                          <span className="title">Sale No: </span>
                          {order.sale_no}-{order.shop_id}-{order.business_id}{' '}
                        </span>
                        <span className="date">{order.sale_date}</span>
                        <span className="status">{order.order_status}</span>
                      </h2>
                    </div>
                  </div>
                  
                  {this.state.orders.length !== ind + 1 && <div className="divider-block"></div> }
                </Link>
              ))
            ) : (
              <div className="not-found">
                <p className="not-found-letter">Empty here</p>
              </div>
            )}
          </div>
        ) : (
          <LoginFacebook editCustomerDetail={this.editCustomerDetail} />
        );
    }
}

export default OrderHistory