import React from 'react';

import './styles.css';
import { Link } from 'react-router-dom';

class MobileFooter extends React.Component {
  constructor() {
    super();
    this.state = {
      showSearch: '',
    };
  }

  showSearch = () => {
    this.setState({
      showSearch: this.state.showSearch ? '' : 'active',
    });
  };

  closeSearch = () => {
    this.setState({
      showSearch: '',
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <ul>
            <li className={`searchLi ${this.state.showSearch}`}>
              <Link to="/mmdp" className="search">
                <span>Search</span>
              </Link>
            </li>
            <li>
              <Link to="/mmdp/discover" className="discover">
                <span>Discover</span>
              </Link>
            </li>
            <li>
              <Link to="/mmdp/collections" className="collection">
                <span>Collections</span>
              </Link>
            </li>
            {/* <div className={`product_search ${this.state.showSearch}`}>
                            <input type="text" placeholder="Search Products" />
                            <button>Search</button>
                        </div> */}
            {/* <li><Link to="/mmdp/comingsoon" className="message"><span>Messages</span></Link></li> */}
            <li>
              <Link to="/mmdp/profile" className="my_account">
                <span>Profile</span>
              </Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default MobileFooter;
